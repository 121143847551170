import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import InfoIcon from "@mui/icons-material/Info";
import PaymentIcon from "@mui/icons-material/Payment";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import TaskDetails from "./TaskDetails";
import BrowseWizzes from "./BrowseWizzes";
import MakePayment from "./MakePayment";
import { API, graphqlOperation } from 'aws-amplify';
import { createTask } from "../../graphql/mutations";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";


const steps = [
	{
		label: "Task Details",
		description: <TaskDetails />,
	},
	{
		label: "Browse Wizzes",
		description: <BrowseWizzes />,
	},
	{
		label: "Make Payment & Assign Task",
		description: <MakePayment />,
	},
];

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
	backgroundColor:
		theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
	zIndex: 1,
	color: "#fff",
	width: 50,
	height: 50,
	display: "flex",
	borderRadius: "50%",
	justifyContent: "center",
	alignItems: "center",
	...(ownerState.active && {
		backgroundImage:
			"linear-gradient( 136deg, #2d324a 0%, #2d324a 50%, #2d324a 100%)",
		boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
	}),
	...(ownerState.completed && {
		backgroundImage:
			"linear-gradient( 136deg, #2d324a 0%, #2d324a 50%, #2d324a 100%)",
	}),
}));

function ColorlibStepIcon(props) {
	const { active, completed, className } = props;

	const icons = {
		1: <InfoIcon />,
		2: <GroupAddIcon />,
		3: <PaymentIcon />,
	};

	return (
		<ColorlibStepIconRoot
			ownerState={{ completed, active }}
			className={className}
		>
			{icons[String(props.icon)]}
		</ColorlibStepIconRoot>
	);
}

ColorlibStepIcon.propTypes = {
	active: PropTypes.bool,
	className: PropTypes.string,
	completed: PropTypes.bool,
	icon: PropTypes.node,
};

async function saveNewTask(task_data) {
	console.log(task_data);

	await API.graphql(
		graphqlOperation(createTask, { input: task_data})
	);
}

export default function VerticalLinearStepper() {
	const navigate = useNavigate();
	const [activeStep, setActiveStep] = React.useState(0);
	const [taskDetails, setTaskDetails] = React.useState({});
	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setTaskDetails( JSON.parse(localStorage.getItem('taskDetails')) );
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};


	async function handleCheckout() {
		try {
			const response = await API.post('gigwizrestapi', '/checkout', {
				body: {
					"BusinessShortCode": "174379",    
					"Password": "MTc0Mzc5YmZiMjc5ZjlhYTliZGJjZjE1OGU5N2RkNzFhNDY3Y2QyZTBjODkzMDU5YjEwZjc4ZTZiNzJhZGExZWQyYzkxOTIwMTYwMjE2MTY1NjI3",    
					"Timestamp":"20160216165627",    
					"TransactionType": "CustomerPayBillOnline",    
					"Amount": "1",    
					"PartyA":"254708374149",    
					"PartyB":"174379",    
					"PhoneNumber":"254708374149",    
					"CallBackURL": "https://3quwqf07od.execute-api.us-east-2.amazonaws.com/dev/update-transaction",    
					"AccountReference":"Test",    
					"TransactionDesc":"Test"
				}
			});
	
			console.log(response);  // Handle the response, e.g., confirmation to the user
			Swal.fire(
				"Paid!",
				`Your payment has been received and ${taskDetails.assignedWizName} has been notified. The job will move from pending to confirmed status after ${taskDetails.assignedWizName} confirms.`,
				"success"
			);
			navigate("/profile/jobs/orders");

		} catch (error) {
			console.error("Error during checkout:", error);
			Swal.fire(
				"Error!",
				`There was an issue processing your payment. Please try again, or contact us if the issue persists.`,
				"error"
			);
			
		}
	}


	const handlePayment = () => {
		saveNewTask(taskDetails);

		Swal.fire({
			title: `Pay ${taskDetails.taskEstimatedCost} for ${taskDetails.assignedWizName}`,
			text: `GigiWiz will hold the funds until the task has been completed.`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, pay now!",
		}).then((result) => {
			if (result.value) {
				handleCheckout();
			}
		});
	};

	return (
		<Box sx={{ maxWidth: 400 }}>
			<Stepper activeStep={activeStep} orientation='vertical'>
				{steps.map((step, index) => (
					<Step key={step.label}>
						<StepLabel StepIconComponent={ColorlibStepIcon}>
							<p style={{ fontSize: "1.8rem" }}>{step.label}</p>
						</StepLabel>
						<StepContent>
							<div>{step.description}</div>
							<Box sx={{ mb: 2 }}>
								<div>
									<Button
										onClick={handleBack}
										sx={{ mt: 1, mr: 1 }}
										style={{
											display: index === 0 ? "none" : "block",
											backgroundColor: "#2d324a",
											color: "#fff",
											fontStyle: "normal",
										}}
									>
										Previous
									</Button>
									<Button
										variant='contained'
										onClick={index === steps.length - 1  ? handlePayment : handleNext }
										sx={{ mt: 1, mr: 1 }}
										style={{
											backgroundColor: "#2d324a",
											color: "#fff",
											fontStyle: "normal",
										}}
									>
										{index === steps.length - 1
											? `Pay ${Intl.NumberFormat("en-US", {
													style: "currency",
													currency: "KES",
											  }).format(taskDetails.taskEstimatedCost)} to Assign Task`
											: "Continue"}
									</Button>
								</div>
							</Box>
						</StepContent>
					</Step>
				))}
			</Stepper>
		</Box>
	);
}

import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useGigWizContext } from "../../../contexts/Gigwiz";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { addDays } from "date-fns";
import { useState } from "react";
// import { DateRange } from "react-date-range";
import { DateRange } from "react-date-range";
import { useEffect } from "react";

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { getUserInfo } from "../../../contexts/auth";
import { listAbsenceBlocks } from "../../../graphql/queries";
import { createAbsenceBlock, updateAbsenceBlock } from "../../../graphql/mutations";
import { API, graphqlOperation } from 'aws-amplify';

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "50vw",
	height: "max-content",
	bgcolor: "#2d324a",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	boxShadow: 24,
	p: 4,
};
const cancelStyle = {
	backgroundColor: "white",
	color: "#2d324a",
	border: "none",
	padding: "1rem 3rem",
	fontSize: "1.5rem",
	fontStyle: "normal",
	cursor: "pointer",
	borderRadius: "0.5rem",
	marginRight: "4.5rem",
};
const submitStyle = {
	backgroundColor: "white",
	color: "#2d324a",
	border: "none",
	padding: "1rem 3rem",
	fontSize: "1.5rem",
	fontStyle: "normal",
	cursor: "pointer",
	borderRadius: "0.5rem",
	marginLeft: "4.5rem",
};

export default function WizCalendar() {
	const { open, handleClose } = useGigWizContext();
	
	const [currAbsenceBlock, setAbsenceBlock] = useState();
	const [selectedDates, setSelectedDates] = useState([]);


	// Get today's date at midnight
	const today = new Date();
	today.setHours(0, 0, 0, 0);

	// 30 days in future
	const future = new Date();
	future.setDate(future.getDate() + 30);

	// initialize selected dates
	const fetchSavedAvailability = async () => {
		const userInfo = await getUserInfo();
		
		try {
			const response = await API.graphql(graphqlOperation(listAbsenceBlocks, {
				filter: {
					userId: { eq: userInfo.id}
				  }
			}));

			if (response.data.listAbsenceBlocks.items.length > 0) {
				setAbsenceBlock(response.data.listAbsenceBlocks.items[0]);
				setSelectedDates(response.data.listAbsenceBlocks.items[0].dateArray);
			}

		} catch (error){
			console.log('Error fetching saved absence block', error);
		}
	};

	React.useEffect(() => {
		fetchSavedAvailability();
	  }, []);

	// Function to save a user's availavility
	const saveAvailability = async () => {
		const userInfo = await getUserInfo();
		
		try {
			if (currAbsenceBlock == null) {
				// create a new absence block for this user
				await API.graphql(graphqlOperation(createAbsenceBlock, {
					input: {
						userId: userInfo.id,
						dateArray: selectedDates
					}
				  }));
				
			} else {
				// use existing absence block if present

				console.log(currAbsenceBlock);
				const toUpdate = {
					id: currAbsenceBlock.id,
					_version: currAbsenceBlock._version,
					dateArray: selectedDates
				  }
				  
				await API.graphql(graphqlOperation(updateAbsenceBlock, {
					input: toUpdate
				  }));
				
			}

		} catch (error){
			console.log('Error updating absence block', error);
		}
	}


	// Function to handle date selection
	const handleDateChange = (date) => {
		const selectedDateStr = date.toISOString().split('T')[0];
		if (selectedDates.includes(selectedDateStr)) {
		  const newDatesArray = selectedDates.filter((d) => d !== selectedDateStr);
		  setSelectedDates(newDatesArray);
		} else {
		  setSelectedDates([...selectedDates, selectedDateStr]);
		}
	  };
	
	  // Function to set tile class (for highlighting)
	  const tileClassName = ({ date, view }) => {
		// Convert tile date to string format "YYYY-MM-DD"
		const tileDateStr = date.toISOString().split('T')[0];
	
		// Check if this date is in the selected dates array
		if (view === 'month' && selectedDates.includes(tileDateStr)) {
		  return 'highlight';  // Return the class name to be added to this date tile
		} else if (view === 'month' && tileDateStr===today.toISOString().split('T')[0]){
			return 'show-today';
		}
	  };

	// Function to disable past dates and dates more that 30 days in the future
	const tileDisabled = ({ date, view }) => {
		return (date < today) || (date > future) ;

	};

	return (
		<div>
			<Modal
				keepMounted
				open={open}
				onClose={handleClose}
				aria-labelledby='keep-mounted-modal-title'
				aria-describedby='keep-mounted-modal-description'
			>
				<Box sx={style}>
					<div>
						<h3 style={{color: "white"}}>Select any dates you are not available to work</h3>
						<Calendar
							onClickDay={handleDateChange}
							tileClassName={tileClassName}
							minDate={today}
        					tileDisabled={tileDisabled}
							showDoubleView={false}
							calendarType='gregory'
						/>
						<style>{`
							.show-today {
								background: white;
								color: #2d324a;
								}
							.highlight {
							background: #FF7276;
							color: white;
							}
						`}</style>
					</div>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							marginTop: "1.5rem",
							width: "100%",
						}}
						className='buttons-container'
					>
						<button style={cancelStyle} onClick={handleClose}>
							Cancel
						</button>
						<button style={submitStyle} 
								onClick={() => {
									handleClose();
									saveAvailability();
									}}>
							Save
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	);
}

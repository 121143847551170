import React, { useState } from "react";
import { Auth } from 'aws-amplify';
import SectionTitle from "../Common/SectionTitle";
import { FaKey } from "react-icons/fa";
import NotificationEntry from "./NotificationEntry";


const sectionTileClass = "profile-settings__account--section-title";
const notificationTiming = [
	{ label: "Immediately", value: "Immediately" },
	{ label: "Never", value: "Never" },
];

function Account({ userData }) {
	const showPreferenceConfig = false;
	const [showResetFrom, setShowResetForm] = useState(true);
	const [oldPassword, setOldPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmNewPassword, setConfirmNewPassword] = useState('');
	const [message, setMessage] = useState('');


	const {
	 email,
	 phone
	} = userData;

	const formStyle = {
		display: 'flex',
		flexDirection: 'column',
		maxWidth: '300px',
		margin: 'auto'
		};

	const [mobileNumber, setMobileNumber] = useState(phone);
	const [userEmail, setEmail] = useState(email);
	const [notificationTime, setNotificationTime] = useState({
		job_cancellation: true,
		job_request: true,
		bill_report: true,
		general_updates: true,
	});
	const [notificationOnWeb, setNotificationOnWeb] = useState({
		job_cancellation: true,
		job_request: true,
		bill_report: true,
		general_updates: true,
	});


	const handleSubmitPasswordChange = async (e) => {
		e.preventDefault();
	
		if (newPassword !== confirmNewPassword) {
		  setMessage("New passwords don't match.");
		  return;
		}
	
		try {
		  const user = await Auth.currentAuthenticatedUser();
		  await Auth.changePassword(user, oldPassword, newPassword);
		  setMessage('Password has been successfully changed.');
		} catch (error) {
		  setMessage(error.message);
		}
	  };

	const handleMobileNumber = (e) => setMobileNumber(e.target.value);
	const handleEmail = (e) => setEmail(e.target.value);
	const handleJobRequestNotificationTimeChanged = () =>
		setNotificationTime({
			...notificationTime,
			job_request: !notificationTime.job_request,
		});
	const handleJobCancellationNotificationTimeChanged = () =>
		setNotificationTime({
			...notificationTime,
			job_cancellation: !notificationTime.job_cancellation,
		});
	const handleBillReportNotificationTimeChanged = () =>
		setNotificationTime({
			...notificationTime,
			bill_report: !notificationTime.bill_report,
		});
	const handleGeneralUpdatesNotificationTimeChanged = () =>
		setNotificationTime({
			...notificationTime,
			general_updates: !notificationTime.general_updates,
		});
	const handleJobRequestNotificationOnWeb = () =>
		setNotificationOnWeb({
			...notificationOnWeb,
			job_request: !notificationOnWeb.job_request,
		});
	const handleJobCancellationNotificationOnWeb = () =>
		setNotificationOnWeb({
			...notificationOnWeb,
			job_cancellation: !notificationOnWeb.job_cancellation,
		});
	const handleBillReportNotificationOnWeb = () =>
		setNotificationOnWeb({
			...notificationOnWeb,
			bill_report: !notificationOnWeb.bill_report,
		});
	const handleGeneralUpdatesNotificationOnWeb = () =>
		setNotificationOnWeb({
			...notificationOnWeb,
			general_updates: !notificationOnWeb.general_updates,
		});

	return (
		<div className='profile-settings__account'>
			<SectionTitle titleClass={sectionTileClass} title='Change Password' />
			<div className='profile-settings__user-profile-overview--info'>
				{showResetFrom && (
					<div>
						<form onSubmit={handleSubmitPasswordChange}
							  style={formStyle}>
						<label>Current Password:</label>
						<input
							type="password"
							value={oldPassword}
							onChange={(e) => setOldPassword(e.target.value)}
							required
						/>
						<label>New Password:</label>
						<input
							type="password"
							value={newPassword}
							onChange={(e) => setNewPassword(e.target.value)}
							required
						/>
						<label>Confirm New Password:</label>
						<input
							type="password"
							value={confirmNewPassword}
							onChange={(e) => setConfirmNewPassword(e.target.value)}
							required
						/>
						<button className='change-password__button' type="submit">Change Password</button>
						</form>
						<div>{message}</div>
				  </div>
				)}
			</div>
			{showPreferenceConfig && (
				<div>
					<SectionTitle titleClass={sectionTileClass} title='Notifications' />
					<div className='profile-settings__user-profile-overview--info'>
						<div className='notifications'>
							<p>Where would you like to receive notifications?</p>
							<div className='notifications__channels-conatiner'>
								<div className='notifications__channels-conatiner--title'>
									<p>Notifications Type</p>
									<p>Web App</p>
									<p>Email</p>
								</div>
								<NotificationEntry
									title='Job resquest'
									emailNotification={notificationTime?.job_request}
									timingCategory={notificationTiming}
									change={handleJobRequestNotificationTimeChanged}
									onWeb={handleJobRequestNotificationOnWeb}
									webNotification={notificationOnWeb?.job_request}
								/>
								<NotificationEntry
									title='Job Cancellation'
									emailNotification={notificationTime?.job_cancellation}
									timingCategory={notificationTiming}
									change={handleJobCancellationNotificationTimeChanged}
									onWeb={handleJobCancellationNotificationOnWeb}
									webNotification={notificationOnWeb?.job_cancellation}
								/>
								<NotificationEntry
									title='Bill Report'
									emailNotification={notificationTime?.bill_report}
									timingCategory={notificationTiming}
									change={handleBillReportNotificationTimeChanged}
									onWeb={handleBillReportNotificationOnWeb}
									webNotification={notificationOnWeb?.bill_report}
								/>
								<NotificationEntry
									title='General Updates'
									emailNotification={notificationTime?.general_updates}
									timingCategory={notificationTiming}
									change={handleGeneralUpdatesNotificationTimeChanged}
									onWeb={handleGeneralUpdatesNotificationOnWeb}
									webNotification={notificationOnWeb?.general_updates}
								/>
							</div>
						</div>
					</div>
					<SectionTitle titleClass={sectionTileClass} title='Danger Zone' />
					<div className='profile-settings__user-profile-overview--info'>
						<div className='delete-account'>
							<p>
								Want to change your password? Click “Change Password” to be guided
								on the process.
							</p>
							<div className='delete-account__button'>
								<p>Delete Account</p>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default Account;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsGearWideConnected as SettingsIcon } from "react-icons/bs";
import emplImage from "../../../assets/images/empl.jpg";
import { useGigWizContext } from "../../../contexts/Gigwiz";
import { listPatronProfiles } from "../../../graphql/queries";
import { API, graphqlOperation } from 'aws-amplify';
import { Storage } from 'aws-amplify';
import { getUserInfo } from "../../../contexts/auth";

function MobileMenu({ leftSideData }) {
	const navigate = useNavigate();
	const { handleShowUserProfileMenu, handleOpen, userData } =
		useGigWizContext();
	const [image, setImage] = useState(emplImage);
	const [getNavData, setNavData] = useState(null);
	const [patronProfile, setPatronProfile] = useState();

	async function fetchProfile(){
		try {
			const userInfo = await getUserInfo();

			const response = await API.graphql(graphqlOperation(listPatronProfiles, {
				filter: {
					gigwiz_user_id: { eq: userInfo.id }
				  }
			}));
			setPatronProfile(response.data.listPatronProfiles.items[0]);

		} catch (error){
			console.log('Error fetching user profile:', error);
		}
	}

	useEffect(() => {
		fetchProfile();
	});

	useEffect(() => {
		if (userData) {
			setNavData({
				id: userData?.id,
				wiz_details_updated: userData?.details.wiz_details_updated,
			});
		}
	}, [userData]);

	const navigateAndCloseMenu = (path) => {
		navigate(path);
		handleShowUserProfileMenu();
	};

	let fileInput = React.createRef();

	const onOpenFileDialog = () => {
		fileInput.current.click();
	};

	const onProcessFile = e => {
		e.preventDefault();
		let reader = new FileReader();
		let file = e.target.files[0];
		try {
			reader.readAsDataURL(file);
		} catch (err) {
			console.log(err);
		}
		reader.onloadend = () => {
			setImage(reader.result);
		};
		Storage.put("profilePicture.png", file, {
			contentType: "image/png"
		})
			.then(result => console.log(result))
			.catch(err => console.log(err));
	};

	const saveDefaultProfiePicture = () => {

		Storage.put("profilePicture.png", emplImage, {
			contentType: "image/png"
		})
			.then(result => console.log(result))
			.catch(err => console.log(err));
	};

	useEffect(() => {
		getProfilePicture();
	  }, []);
	
	
	const getProfilePicture = () => {
		Storage.get("profilePicture.png")
		  .then(url => {
			var myRequest = new Request(url);
			fetch(myRequest).then(function(response) {
			  if (response.status === 200) {
				setImage(url);
			  } else {
				saveDefaultProfiePicture();
			  }
			});
		  })
		  .catch(err => ( console.log(err)) );
	  };


	return (
		<div className='user-profile-section__mobile-menu'>
			<div className='user-profile-section__mobile-menu--profile-image'>
				<a href="/">
					<input
						type="file"
						key='profile_picture'
						onChange={onProcessFile}
						ref={fileInput}
						hidden={true}
					/>
				</a>
				<img src={image} alt='click to update image' onClick={onOpenFileDialog} title='click to update image' />
				<p>{patronProfile?.first_name || 'Guest User'}</p>
			</div>
			<div className='user-profile-section__mobile-menu--menu-items'>
				<ul>
					{leftSideData?.map((item) => {
						const { id, icon, title, path } = item;
						return id === 5 &&
							getNavData?.wiz_details_updated === false ? null : id === 4 &&
						  getNavData?.wiz_details_updated ? null : (
							<li
								key={id}
								onClick={id === 5 ? handleOpen : () => navigateAndCloseMenu(path)}
							>
								<p>
									{icon}
									<span>{title}</span>
								</p>
							</li>
						);
					})}
					<li>
						<p onClick={() => navigateAndCloseMenu("/profile/details-update")}>
							<SettingsIcon />
							<span>Profile Settings</span>
						</p>
					</li>
				</ul>
			</div>
		</div>
	);
}

export default MobileMenu;

import React from "react";
import About from "../../components/About/About";

function AboutPage() {
	return <div>
		<About/>
		</div>;
}

export default AboutPage;

const pastPayments = [];
const paymentsInfo = [
	{
		title: "May 2022",
		id: 1,
		tasks: [
			{
				id: "3efrs2",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 2000,
			},
			{
				id: "3efqw2",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 1000,
			},
			{
				id: "3efmt2",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 3000,
			},
			{
				id: "3efrcc",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 200,
			},
			{
				id: "3efrb4",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 100,
			},
			{
				id: "3efrr2",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 6000,
			},
			{
				id: "3efrp2",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 8000,
			},
			{
				id: "3efrk2",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 21000,
			},
			{
				id: "3efrz2",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 400,
			},
		],
	},
	{
		title: "Apr 2022",
		id: 2,
		tasks: [
			{
				id: "3efrs2",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 200,
			},
			{
				id: "3efqw2",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 600,
			},
			{
				id: "3efmt2",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 300,
			},
			{
				id: "3efrcc",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 900,
			},
			{
				id: "3efrb4",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 400,
			},
			{
				id: "3efrr2",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 2000,
			},
			{
				id: "3efrp2",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 2000,
			},
			{
				id: "3efrk2",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 2000,
			},
			{
				id: "3efrz2",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 2000,
			},
		],
	},
	{
		title: "Mar 2022",
		id: 3,
		tasks: [
			{
				id: "3efrs2",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 1000,
			},
			{
				id: "3efqw2",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 1000,
			},
			{
				id: "3efmt2",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 3000,
			},
			{
				id: "3efrcc",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 2000,
			},
			{
				id: "3efrb4",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 1000,
			},
			{
				id: "3efrr2",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 200,
			},
			{
				id: "3efrp2",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 100,
			},
			{
				id: "3efrk2",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 700,
			},
			{
				id: "3efrz2",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 2000,
			},
		],
	},
	{
		title: "Feb 2022",
		id: 4,
		tasks: [
			{
				id: "3efrs2",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 2000,
			},
			{
				id: "3efqw2",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 2000,
			},
			{
				id: "3efmt2",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 2000,
			},
			{
				id: "3efrcc",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 2000,
			},
			{
				id: "3efrb4",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 2000,
			},
			{
				id: "3efrr2",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 2000,
			},
			{
				id: "3efrp2",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 2000,
			},
			{
				id: "3efrk2",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 2000,
			},
			{
				id: "3efrz2",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 2000,
			},
		],
	},
	{
		title: "Jan 2022",
		id: 5,
		tasks: [
			{
				id: "3efrs2",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 2000,
			},
			{
				id: "3efqw2",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 2000,
			},
			{
				id: "3efmt2",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 2000,
			},
			{
				id: "3efrcc",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 2000,
			},
			{
				id: "3efrb4",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 2000,
			},
			{
				id: "3efrr2",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 2000,
			},
			{
				id: "3efrp2",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 2000,
			},
			{
				id: "3efrk2",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 2000,
			},
			{
				id: "3efrz2",
				category: "My Gig",
				description: "Electrical Installation Kileleshwa Nairobi",
				date: "Jan 22, 2022",
				time: "1400Hrs",
				amount: 2000,
			},
		],
	},
];

export { paymentsInfo, pastPayments };

import React from "react";
import { useNavigate } from "react-router-dom";
import { BsGearWideConnected as SettingsIcon } from "react-icons/bs";
import gigWizLogo from "../../../assets/images/gigwiz-logo.png";

function TopBarDesktop() {
	const navigate = useNavigate();
	return (
		<div className='user-profile-section__container--top-bar'>
			<div
				onClick={() => navigate("/")}
				className='user-profile-section__container--top-bar__logo'
			>
				<img src={gigWizLogo} alt='gigwiz-logo' />
			</div>
			<div className='user-profile-section__container--top-bar__profile-settings'>
				<p onClick={() => navigate("/profile/details-update")}>
					<SettingsIcon /> Profile Settings
				</p>
			</div>
		</div>
	);
}

export default TopBarDesktop;

import React from "react";

function MbileTabs({ classProp, tabData, handleClick, closeChat }) {

	const [showClicked, setShowClicked] = React.useState(0);
	const clickedStyle = {
		background: '#f8d12c',
		color: '#2d324a'
	}
	

	return (
		<div className={classProp}>
			{tabData.map((item, index) => {
				return (
					<p
						className={"tab-entry"}
						style={
							showClicked === index ? clickedStyle : {}
						}
						onClick={() => {
							closeChat();
							setShowClicked(index);
							handleClick(index);
						}}
						key={item}
					>
						{item}
					</p>
				);
			})}
		</div>
	);
}

export default MbileTabs;

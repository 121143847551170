import React, { useEffect, useState } from "react";
import background from "../../assets/images/line-3.jpg";
import { useNavigate } from "react-router-dom";

const dummyData = [
	"Plumbing",
	"Laundry",
	"House Cleaning",
	"Meal Prep",
	"tutoring",
	"and more ..."
];

function Hero() {
	const navigate = useNavigate();
	const [searchItem, setSearchItem] = useState("");
	const [openSearch, setOpenSearch] = useState(false);
	const [selectedValue, setSelectedValue] = useState("");

	const handleSelectedValue = (item) => {
		setSelectedValue(item);
		setSearchItem("");
		setOpenSearch(false);
	};

	const handleChange = (e) => setSearchItem(e.target.value);
	const handleSubmit = (e) => {
		e.preventDefault();
		navigate(`/account`);
	};

	useEffect(() => {
		if (searchItem.length > 0) {
			setOpenSearch(true);
		} else {
			setOpenSearch(false);
		}
	}, [searchItem]);

	return (
		<section>
			<div className='hero-section'>
				<div className='hero-section__overlay'></div>
				<div className='hero-section__content'>
					<h1 className='hero-section__content--title'>
						Redefining work, one gig at a time.
					</h1>
					<p className='hero-section__subtitle'>
						What task do you need to get done?
					</p>

					<div className='hero-section__content--tasks-preview'>
							<ul>
								{dummyData.slice(0, 3).map((item, index) => {
									return (
										<li onClick={handleSubmit} key={index}>
											{item}
										</li>
									);
								})}
							</ul>
					</div>
					<div className='hero-section__content--tasks-preview'>
							<ul>
								{dummyData.slice(3, 6).map((item, index) => {
									return (
										<li onClick={handleSubmit} key={index}>
											{item}
										</li>
									);
								})}
							</ul>
					</div>
					
				</div>
				<img
					className='hero-section__background'
					src={background}
					alt='hero-bg'
				/>
			</div>
		</section>
	);
}

export default Hero;

import React from "react";
import { leftSideData } from "../MainProfileComponent";
import { AiFillCopyrightCircle } from "react-icons/ai";
import { currentYear } from "../../Common/Footer/Footer";
import TopBarDesktop from "../Top Bar/TopBarDesktop";
import TopBarMobile from "../Top Bar/TopBarMobile";
import MobileMenu from "../Mobile Menu/MobileMenu";
import { useGigWizContext } from "../../../contexts/Gigwiz";
import SideBar from "../Sidebar/SideBar";

function SetAvailability() {
	const {showUserProfileMenu} = useGigWizContext();
	return (
		<section className='user-profile-section'>
			{showUserProfileMenu && <MobileMenu leftSideData={leftSideData} />}
			<div className='user-profile-section__container'>
				<TopBarDesktop />
				<TopBarMobile />
				<div className='user-profile-section__container--main'>
				<SideBar />
					<div className='user-profile-section__container--main__right-side'>
						<div className='user-profile-section__container--main__right-side--main-content'>
							<div className='user-profile-section__container--main__right-side--main-content__container'>
								<p>Availability Settings</p>
							</div>
						</div>

						<div className='user-profile-section__container--main__right-side--help'>
							<p> Need Help?</p>
						</div>
						<div className='user-profile-section__container--main__right-side--footer'>
							<p>
								<AiFillCopyrightCircle /> {currentYear} GigWiz | All Rights
								Reserved
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default SetAvailability;

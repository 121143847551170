/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAbsenceBlock = /* GraphQL */ `
  mutation CreateAbsenceBlock(
    $input: CreateAbsenceBlockInput!
    $condition: ModelAbsenceBlockConditionInput
  ) {
    createAbsenceBlock(input: $input, condition: $condition) {
      id
      userId
      dateArray
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAbsenceBlock = /* GraphQL */ `
  mutation UpdateAbsenceBlock(
    $input: UpdateAbsenceBlockInput!
    $condition: ModelAbsenceBlockConditionInput
  ) {
    updateAbsenceBlock(input: $input, condition: $condition) {
      id
      userId
      dateArray
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAbsenceBlock = /* GraphQL */ `
  mutation DeleteAbsenceBlock(
    $input: DeleteAbsenceBlockInput!
    $condition: ModelAbsenceBlockConditionInput
  ) {
    deleteAbsenceBlock(input: $input, condition: $condition) {
      id
      userId
      dateArray
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createMessageThread = /* GraphQL */ `
  mutation CreateMessageThread(
    $input: CreateMessageThreadInput!
    $condition: ModelMessageThreadConditionInput
  ) {
    createMessageThread(input: $input, condition: $condition) {
      id
      TaskId
      wizId
      patronId
      Messages {
        items {
          id
          TaskId
          senderId
          recieverId
          messageBody
          messageStatus
          messagethreadID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateMessageThread = /* GraphQL */ `
  mutation UpdateMessageThread(
    $input: UpdateMessageThreadInput!
    $condition: ModelMessageThreadConditionInput
  ) {
    updateMessageThread(input: $input, condition: $condition) {
      id
      TaskId
      wizId
      patronId
      Messages {
        items {
          id
          TaskId
          senderId
          recieverId
          messageBody
          messageStatus
          messagethreadID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteMessageThread = /* GraphQL */ `
  mutation DeleteMessageThread(
    $input: DeleteMessageThreadInput!
    $condition: ModelMessageThreadConditionInput
  ) {
    deleteMessageThread(input: $input, condition: $condition) {
      id
      TaskId
      wizId
      patronId
      Messages {
        items {
          id
          TaskId
          senderId
          recieverId
          messageBody
          messageStatus
          messagethreadID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      TaskId
      senderId
      recieverId
      messageBody
      messageStatus
      messagethreadID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      TaskId
      senderId
      recieverId
      messageBody
      messageStatus
      messagethreadID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      TaskId
      senderId
      recieverId
      messageBody
      messageStatus
      messagethreadID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createTask = /* GraphQL */ `
  mutation CreateTask(
    $input: CreateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    createTask(input: $input, condition: $condition) {
      id
      assignedWizId
      assignedWizName
      patronId
      patronName
      taskStartDate
      taskDescription
      taskCategory
      taskLocation
      taskEstimatedCost
      taskStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateTask = /* GraphQL */ `
  mutation UpdateTask(
    $input: UpdateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    updateTask(input: $input, condition: $condition) {
      id
      assignedWizId
      assignedWizName
      patronId
      patronName
      taskStartDate
      taskDescription
      taskCategory
      taskLocation
      taskEstimatedCost
      taskStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteTask = /* GraphQL */ `
  mutation DeleteTask(
    $input: DeleteTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    deleteTask(input: $input, condition: $condition) {
      id
      assignedWizId
      assignedWizName
      patronId
      patronName
      taskStartDate
      taskDescription
      taskCategory
      taskLocation
      taskEstimatedCost
      taskStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPatronProfile = /* GraphQL */ `
  mutation CreatePatronProfile(
    $input: CreatePatronProfileInput!
    $condition: ModelPatronProfileConditionInput
  ) {
    createPatronProfile(input: $input, condition: $condition) {
      id
      first_name
      gigwiz_user_id
      status
      rating
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePatronProfile = /* GraphQL */ `
  mutation UpdatePatronProfile(
    $input: UpdatePatronProfileInput!
    $condition: ModelPatronProfileConditionInput
  ) {
    updatePatronProfile(input: $input, condition: $condition) {
      id
      first_name
      gigwiz_user_id
      status
      rating
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePatronProfile = /* GraphQL */ `
  mutation DeletePatronProfile(
    $input: DeletePatronProfileInput!
    $condition: ModelPatronProfileConditionInput
  ) {
    deletePatronProfile(input: $input, condition: $condition) {
      id
      first_name
      gigwiz_user_id
      status
      rating
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createWorkerProfile = /* GraphQL */ `
  mutation CreateWorkerProfile(
    $input: CreateWorkerProfileInput!
    $condition: ModelWorkerProfileConditionInput
  ) {
    createWorkerProfile(input: $input, condition: $condition) {
      id
      first_name
      gigwiz_user_id
      status
      services
      work_locations
      experience
      main_service
      rating
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateWorkerProfile = /* GraphQL */ `
  mutation UpdateWorkerProfile(
    $input: UpdateWorkerProfileInput!
    $condition: ModelWorkerProfileConditionInput
  ) {
    updateWorkerProfile(input: $input, condition: $condition) {
      id
      first_name
      gigwiz_user_id
      status
      services
      work_locations
      experience
      main_service
      rating
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteWorkerProfile = /* GraphQL */ `
  mutation DeleteWorkerProfile(
    $input: DeleteWorkerProfileInput!
    $condition: ModelWorkerProfileConditionInput
  ) {
    deleteWorkerProfile(input: $input, condition: $condition) {
      id
      first_name
      gigwiz_user_id
      status
      services
      work_locations
      experience
      main_service
      rating
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createGigwizUser = /* GraphQL */ `
  mutation CreateGigwizUser(
    $input: CreateGigwizUserInput!
    $condition: ModelGigwizUserConditionInput
  ) {
    createGigwizUser(input: $input, condition: $condition) {
      id
      phone_number
      email
      date_of_birth
      languages
      verified
      first_name
      last_name
      street
      city
      country
      gender
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateGigwizUser = /* GraphQL */ `
  mutation UpdateGigwizUser(
    $input: UpdateGigwizUserInput!
    $condition: ModelGigwizUserConditionInput
  ) {
    updateGigwizUser(input: $input, condition: $condition) {
      id
      phone_number
      email
      date_of_birth
      languages
      verified
      first_name
      last_name
      street
      city
      country
      gender
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteGigwizUser = /* GraphQL */ `
  mutation DeleteGigwizUser(
    $input: DeleteGigwizUserInput!
    $condition: ModelGigwizUserConditionInput
  ) {
    deleteGigwizUser(input: $input, condition: $condition) {
      id
      phone_number
      email
      date_of_birth
      languages
      verified
      first_name
      last_name
      street
      city
      country
      gender
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;

import React, { useState } from "react";
import { useGigWizContext } from "../../../../contexts/Gigwiz";
import Loading from "../../../../Loader";
import SectionTitle from "../Common/SectionTitle";
import { ImLocation2 as Pin } from "react-icons/im";
import {
	getDaysInMonth,
	years,
	monthsOrdered,
	defaultMonth,
	defaultDay,
	makeAWSDateString
} from "./utils";
import mpseaIcon from "../../../../assets/images/mpsea-icon.png";
import moment from "moment";
import { DataStore } from '@aws-amplify/datastore';
import { GigwizUser } from '../../../../models';
import { getCurrentUser } from "../../../../contexts/auth";

const sectionTileClass =
	"profile-settings__user-profile-overview--section-title";

function Profile({ userData }) {
	const { isLoading } = useGigWizContext();
	const [showUpdateButton, setShowUpdateButton] = React.useState(false);

	const {
			address: { street, city, country },
			birthday: { year, month, day },
			email,
			name: { first_name, last_name },
			phone,
			gender,
			skills_approval,
			profile_created_at
	} = userData;


	const [loggedUserData, setLoggedUserData] = useState({
		firstName: first_name,
		lastName: last_name,
		street: street ? street : '',
		city: city ? city : '',
		country: country ? country : '',
		mobileNumber: phone,
		email: email ? email : '',
		userGender: gender ? gender : '',
		yearOfBirth: year ? year : years[0],
		monthOfBirth: month ? month : defaultMonth,
		dayOfBirth: day ? day : defaultDay,
	});

	const handleFirstName = (e) => {
		setLoggedUserData({ ...loggedUserData, firstName: e.target.value });
		showUpdate();
	};
	const handleLastName = (e) => {
		setLoggedUserData({ ...loggedUserData, lastName: e.target.value });
		showUpdate();
	};


	const handleUserGender = (e) => {
	 	setLoggedUserData({ ...loggedUserData, userGender: e.target.value });
	 	showUpdate();
	};
	const handleYearOfBirth = (e) => {
		setLoggedUserData({ ...loggedUserData, yearOfBirth: e.target.value });
		showUpdate();
	};
	const handleMonthOfBirth = (e) => {
		setLoggedUserData({ ...loggedUserData, monthOfBirth: e.target.value });
		showUpdate();
	};
	const handleDayOfBirth = (e) => {
		setLoggedUserData({ ...loggedUserData, dayOfBirth: e.target.value });
		showUpdate();
	};

	const handleHideUpdateButton = () => {
		setShowUpdateButton(false);
	};

	const showUpdate = () => {
		setShowUpdateButton(true);
	};


	const submitUpdate = async (loggedUserData) => {
		
		try {
			alert('Success: Profile updated');
			const curr = getCurrentUser();
			const resultArray = await DataStore.query(
				GigwizUser, 
				c => c.phone_number.eq(curr.phone_number)
			);
			
			await DataStore.save(GigwizUser.copyOf(resultArray[0], item => {

				const dob = makeAWSDateString(
					loggedUserData.yearOfBirth,
					loggedUserData.monthOfBirth,
					loggedUserData.dayOfBirth
				)
			
				item.first_name = loggedUserData.firstName;
				item.last_name = loggedUserData.lastName;
				item.street = loggedUserData.street;
				item.city = loggedUserData.city;
				item.country = loggedUserData.country;
				item.email = loggedUserData.email;
				item.date_of_birth = dob;
				item.gender = loggedUserData.userGender;
			}));
			handleHideUpdateButton();
			

		} catch (error){
			alert('Error updating profile. Please try again');
			console.log(Error);
		}
		
	};

	//console.log(loggedUserData);

	return (
		<div className='profile-settings__user-profile-overview'>
			{isLoading ? (
				<Loading />
			) : isLoading === false && !userData ? (
				<Loading />
			) : (
				<>
					<SectionTitle titleClass={sectionTileClass} title='Personal Data' />
					<div className='profile-settings__user-profile-overview--info'>
						<p>Screen Name</p>
						<div className='faint-bg'>
							<p>
								<span>{first_name}</span> <span>{last_name}</span>
							</p>
						</div>
					</div>
					<div className='profile-settings__user-profile-overview--info'>
						<p>Full Name</p>
						<div className='details-capture'>
							<input
								value={loggedUserData?.firstName}
								key='first_name'
								onChange={handleFirstName}
								type='text'
								placeholder='First Name'
							/>
							<input
								value={loggedUserData?.lastName}
								key='last_name'
								onChange={handleLastName}
								type='text'
								placeholder='Last Name'
							/>
						</div>
					</div>
					<div className='profile-settings__user-profile-overview--info'>
						<p>Email Address</p>
						<div className='details-capture'>
							<input
								value={loggedUserData?.email}
								onChange={(e) => {
									setLoggedUserData({ ...loggedUserData, email: e.target.value });
									showUpdate();
									}
								}
								type='text'
								placeholder='Enter your email address'
							/>
						</div>
					</div>
					
					<div className='profile-settings__user-profile-overview--info'>
						<p>Gender</p>
						<div className='gender'>
							
							<select
								onChange={handleUserGender}
								name='get_gender'
								id='valid_gender'
								defaultValue='DEFAULT'
							>
								{
								loggedUserData.userGender === ''?
								<option key='default' value="DEFAULT" disabled>Choose here</option>
								:<option key='default' value="DEFAULT" disabled>{loggedUserData.userGender}</option>
								}
								{['Male','Female','Other','Prefer not to say'].map((opt, i) => {
									return (
										<option key={i} value={opt}>
											{opt}
										</option>
									);
								})}
							</select>
							
							
						</div>
					</div>
					<div className='profile-settings__user-profile-overview--info'>
						<p>Date of Birth</p>
						<div className='date-of-birth'>
							<div className='date-of-birth__elemement'>
								<p>{loggedUserData?.yearOfBirth}</p>
								<div></div>
								<div className='date-of-birth__elemement--dropdown'>
									<select
										onChange={handleYearOfBirth}
										name='get_years'
										id='valid_years'
									>
										{years?.map((year, i) => {
											return (
												<option key={i} value={year}>
													{year}
												</option>
											);
										})}
									</select>
								</div>
							</div>
							<div className='date-of-birth__elemement'>
								<p>{loggedUserData?.monthOfBirth}</p>
								<div>{/* <ArrowDown /> */}</div>
								<div className='date-of-birth__elemement--dropdown'>
									<select
										onChange={handleMonthOfBirth}
										name='get_months'
										id='valid_months'
									>
										{monthsOrdered()?.map((month, i) => {
											return (
												<option key={i} value={month}>
													{month}
												</option>
											);
										})}
									</select>
								</div>
							</div>
							<div className='date-of-birth__elemement'>
								<p>{loggedUserData?.dayOfBirth}</p>
								<div>{/* <ArrowDown /> */}</div>
								<div className='date-of-birth__elemement--dropdown'>
									<select
										onChange={handleDayOfBirth}
										name='get_dates'
										id='valid_dates'
									>
										{getDaysInMonth(
											monthsOrdered().indexOf(loggedUserData?.monthOfBirth) + 1,
											loggedUserData?.yearOfBirth
										)?.map((date, i) => {
											return (
												<option key={i} value={date}>
													{date}
												</option>
											);
										})}
									</select>
								</div>
							</div>
						</div>
					</div>
					<SectionTitle titleClass={sectionTileClass} title='Address' />
					<div className='profile-settings__user-profile-overview--info'>
						<p>Address</p>
						<div className='address'>
							<Pin />
							<input
								value={loggedUserData?.street}
								key='street'
								onChange={(e) => {
									setLoggedUserData({ ...loggedUserData, street: e.target.value });
									showUpdate();}
								}
								type='text'
								placeholder='Street'
							/>
							<input
								value={loggedUserData?.city}
								key='city'
								onChange={(e) => {
									setLoggedUserData({ ...loggedUserData, city: e.target.value });
									showUpdate();}
								}
								type='text'
								placeholder='City'
							/>
							<input
								value={loggedUserData?.country}
								key='country'
								onChange={(e) => {
									setLoggedUserData({ ...loggedUserData, country: e.target.value });
									showUpdate();}
								}
								type='text'
								placeholder='Country'
							/>
						</div>
					</div>
					<SectionTitle titleClass={sectionTileClass} title='Payment Information' />
					<div className='profile-settings__user-profile-overview--info'>
						<p>Payout Method</p>
						<div className='payout-method'>
							<img src={mpseaIcon} alt='payout method' />
						</div>
					</div>
					<div className='profile-settings__user-profile-overview--info'>
						<p>Telephone No.</p>
						<div className='faint-bg'>
							<p>{loggedUserData?.mobileNumber}</p>
						</div>
					</div>
					
					<SectionTitle
						titleClass={sectionTileClass}
						title='Membership Length'
					/>
					<div className='profile-settings__skills--info'>
						<p>Member Since</p>
						<div
							className={
								skills_approval
									? "skill-approval-approved"
									: "skill-approval-pending"
							}
						>
							<p style={{ width: "max-content" }}>
								{profile_created_at}
							</p>
							{" "}
						</div>
							<p style={{backgroundColor:'transparent',marginLeft:'5vh'}}>
								{moment(
									profile_created_at,
									"YYYYMMDD"
								).fromNow().split(" ").slice(0, 2).join(" ")}
							</p>
					</div>
				</>
			)}
			{showUpdateButton && (
							<div className='user-profile-section__container--main__right-side--user-details-btn-container'>
								<button onClick={() => {submitUpdate(loggedUserData)}}>Update Changes</button>
								<button onClick={handleHideUpdateButton}>Cancel</button>
							</div>
						)}
		</div>
	);
}

export default Profile;
import React from "react";

function NotificationEntry({ title, emailNotification, change, timingCategory, onWeb, webNotification }) {
	return (
		<div className='notifications__channels-conatiner--entry'>
			<div className='notifications__channels-conatiner--entry__category'>
				<p>
					{title}
				</p>
			</div>
			<div className='notifications__channels-conatiner--entry__toogle'>
				<div style={{backgroundColor:webNotification?"green":'#2d324a'}} onClick={onWeb} onDragEnd={onWeb}>
					<p className={webNotification?"active-toogle":"inactive-toogle"}></p>
				</div>
			</div>
			<div className='notifications__channels-conatiner--entry__toogle'>
				<div style={{backgroundColor:emailNotification?"green":'#2d324a'}} onClick={change} onDragEnd={change}>
					<p className={emailNotification?"active-toogle":"inactive-toogle"}></p>
				</div>
			</div>
		</div>
	);
}

export default NotificationEntry;

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAbsenceBlock = /* GraphQL */ `
  query GetAbsenceBlock($id: ID!) {
    getAbsenceBlock(id: $id) {
      id
      userId
      dateArray
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAbsenceBlocks = /* GraphQL */ `
  query ListAbsenceBlocks(
    $filter: ModelAbsenceBlockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAbsenceBlocks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        dateArray
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAbsenceBlocks = /* GraphQL */ `
  query SyncAbsenceBlocks(
    $filter: ModelAbsenceBlockFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAbsenceBlocks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        dateArray
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMessageThread = /* GraphQL */ `
  query GetMessageThread($id: ID!) {
    getMessageThread(id: $id) {
      id
      TaskId
      wizId
      patronId
      Messages {
        items {
          id
          TaskId
          senderId
          recieverId
          messageBody
          messageStatus
          messagethreadID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMessageThreads = /* GraphQL */ `
  query ListMessageThreads(
    $filter: ModelMessageThreadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageThreads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        TaskId
        wizId
        patronId
        Messages {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMessageThreads = /* GraphQL */ `
  query SyncMessageThreads(
    $filter: ModelMessageThreadFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMessageThreads(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        TaskId
        wizId
        patronId
        Messages {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      TaskId
      senderId
      recieverId
      messageBody
      messageStatus
      messagethreadID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        TaskId
        senderId
        recieverId
        messageBody
        messageStatus
        messagethreadID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMessages = /* GraphQL */ `
  query SyncMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        TaskId
        senderId
        recieverId
        messageBody
        messageStatus
        messagethreadID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const messagesByMessagethreadID = /* GraphQL */ `
  query MessagesByMessagethreadID(
    $messagethreadID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByMessagethreadID(
      messagethreadID: $messagethreadID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        TaskId
        senderId
        recieverId
        messageBody
        messageStatus
        messagethreadID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTask = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      id
      assignedWizId
      assignedWizName
      patronId
      patronName
      taskStartDate
      taskDescription
      taskCategory
      taskLocation
      taskEstimatedCost
      taskStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listTasks = /* GraphQL */ `
  query ListTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        assignedWizId
        assignedWizName
        patronId
        patronName
        taskStartDate
        taskDescription
        taskCategory
        taskLocation
        taskEstimatedCost
        taskStatus
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTasks = /* GraphQL */ `
  query SyncTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTasks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        assignedWizId
        assignedWizName
        patronId
        patronName
        taskStartDate
        taskDescription
        taskCategory
        taskLocation
        taskEstimatedCost
        taskStatus
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPatronProfile = /* GraphQL */ `
  query GetPatronProfile($id: ID!) {
    getPatronProfile(id: $id) {
      id
      first_name
      gigwiz_user_id
      status
      rating
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPatronProfiles = /* GraphQL */ `
  query ListPatronProfiles(
    $filter: ModelPatronProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPatronProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        first_name
        gigwiz_user_id
        status
        rating
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPatronProfiles = /* GraphQL */ `
  query SyncPatronProfiles(
    $filter: ModelPatronProfileFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPatronProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        first_name
        gigwiz_user_id
        status
        rating
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getWorkerProfile = /* GraphQL */ `
  query GetWorkerProfile($id: ID!) {
    getWorkerProfile(id: $id) {
      id
      first_name
      gigwiz_user_id
      status
      services
      work_locations
      experience
      main_service
      rating
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listWorkerProfiles = /* GraphQL */ `
  query ListWorkerProfiles(
    $filter: ModelWorkerProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkerProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        first_name
        gigwiz_user_id
        status
        services
        work_locations
        experience
        main_service
        rating
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncWorkerProfiles = /* GraphQL */ `
  query SyncWorkerProfiles(
    $filter: ModelWorkerProfileFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWorkerProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        first_name
        gigwiz_user_id
        status
        services
        work_locations
        experience
        main_service
        rating
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getGigwizUser = /* GraphQL */ `
  query GetGigwizUser($id: ID!) {
    getGigwizUser(id: $id) {
      id
      phone_number
      email
      date_of_birth
      languages
      verified
      first_name
      last_name
      street
      city
      country
      gender
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listGigwizUsers = /* GraphQL */ `
  query ListGigwizUsers(
    $filter: ModelGigwizUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGigwizUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        phone_number
        email
        date_of_birth
        languages
        verified
        first_name
        last_name
        street
        city
        country
        gender
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncGigwizUsers = /* GraphQL */ `
  query SyncGigwizUsers(
    $filter: ModelGigwizUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGigwizUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        phone_number
        email
        date_of_birth
        languages
        verified
        first_name
        last_name
        street
        city
        country
        gender
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

import React, { useEffect } from "react";
import { leftSideData } from "../MainProfileComponent";
import { AiFillCopyrightCircle } from "react-icons/ai";
import { currentYear } from "../../Common/Footer/Footer";
import TopBarDesktop from "../Top Bar/TopBarDesktop";
import TopBarMobile from "../Top Bar/TopBarMobile";
import MobileMenu from "../Mobile Menu/MobileMenu";
import { useGigWizContext } from "../../../contexts/Gigwiz";
import SideBar from "../Sidebar/SideBar";
import { Account, Profile, Skills } from "./Settings";
import Loading from "../../../Loader";
import Calendar from "../Calendar/Calendar";
import { DataStore } from '@aws-amplify/datastore';
import { GigwizUser, WorkerProfile } from '../../../models';
import { getCurrentUser } from "../../../contexts/auth";
import {
	getAlphabeticMonths
} from "./Settings/utils";

const tabs = ["Profile", "Skills", "Account"];



function WizDetailsUpdate() {
	const { showUserProfileMenu } = useGigWizContext();
	const [activeTab, setActiveTab] = React.useState(0);
	const [showUpdateButton, setShowUpdateButton] = React.useState(false);
	const [userInfo, setUserInfo] = React.useState({});
	const [userWorkerProfile, setWorkerProfile] = React.useState({});

	const handleShowUpdateButton = () => {
		setShowUpdateButton(true);
	};
	const handleHideUpdateButton = () => {
		setShowUpdateButton(false);
	};
	const handleActiveTab = (index) => {
		setActiveTab(index);
	};

	useEffect(() => {
		async function getUserData() {
			const curr = getCurrentUser();
			const resultArray = await DataStore.query(
				GigwizUser, 
				c => c.email.eq(curr.email)
			);

			const userObj = resultArray[0];
			const {
					first_name,
					last_name,
					phone_number,
					createdAt,
					date_of_birth,
					verified,
					email,
					gender,
					street,
					city,
					country

			} = userObj;
		
			
			const details = {
				name: { first_name: first_name, last_name: last_name },
				phone: phone_number,
				gender: gender,
				skills_approval: verified,
				profile_created_at: createdAt,
				availability: { range: '' },
				address: { street: street, city: city, country: country },
				birthday: { 
					year: date_of_birth.substring(0, 4), 
					month: getAlphabeticMonths(parseInt(date_of_birth.substring(5, 7))), 
					day: parseInt(date_of_birth.substring(9, 10))
				},
				email: email,
			};
			setUserInfo(details);

			// Get user's worker profile
			
			const resultArray2 = await DataStore.query(
				WorkerProfile,
				c => c.gigwiz_user_id.eq(userObj.id)
			);
			//console.log(userObj);
			const userWorkerProfile = resultArray2[0];
			//console.log(resultArray2);
			setWorkerProfile(userWorkerProfile);
		}
		getUserData();

		/* // Clear local data store
		async function clearDataStore() {
			try {
			  await DataStore.clear();
			  console.log('DataStore has been cleared.');
			} catch (error) {
			  console.error('Error clearing DataStore:', error);
			}
		  }
		
		  clearDataStore();
		  */
	});

	return (
		<section className='user-profile-section'>
			{showUserProfileMenu && <MobileMenu leftSideData={leftSideData} />}
			<div className='user-profile-section__container'>
				<TopBarDesktop />
				<TopBarMobile />
				<div className='user-profile-section__container--main'>
					<SideBar />
					<div className='user-profile-section__container--main__right-side'>
						<div className='user-profile-section__container--main__right-side--main-content'>
							<div className='profile-settings'>
								<div className='profile-settings__header'>
									<h2 className='profile-settings__header--title'>Settings</h2>
								</div>
								<div className='profile-settings__tabs'>
									{tabs.map((tab, index) => {
										return (
											<p
												className={
													activeTab === index
														? "profile-settings__tabs--active-tab"
														: undefined
												}
												onClick={() => handleActiveTab(index)}
												key={index}
											>
												{tab}
											</p>
										);
									})}
								</div>
								<div className='profile-settings__underline'></div>
								{ JSON.stringify(userInfo) === '{}' ? (
									<>
										<Loading />
									</>
								) : (
									<>
										{activeTab === 0 ? (
											<Profile
												userData={userInfo}
											/>
										) : activeTab === 1 ? (
											<Skills
												userWorkerProfile={userWorkerProfile}
											/>
										) : (
											<Account
												userData={userInfo}
												showUpdate={handleShowUpdateButton}
											/>
										)}
									</>
								)}
							</div>
						</div>

						<div className='user-profile-section__container--main__right-side--help'>
							<p> Need Help?</p>
						</div>
						{showUpdateButton && (
							<div className='user-profile-section__container--main__right-side--user-details-btn-container'>
								<button>Update Changes</button>
								<button onClick={handleHideUpdateButton}>Cancel</button>
							</div>
						)}
						<div className='user-profile-section__container--main__right-side--footer'>
							<p>
								<AiFillCopyrightCircle /> {currentYear} GigWiz | All Rights
								Reserved
							</p>
						</div>
					</div>
				</div>
			</div>
			<Calendar />
		</section>
	);
}

export default WizDetailsUpdate;

import React, { useState } from "react"; 
import { Link } from "react-router-dom";

function Banner({ title, subtitle, image, bannerStart, searchVisibility }) {
	const banerSpan = title.split(" ").slice(bannerStart).join(" ");
	const outOfSpan = title.split(" ").slice(0, bannerStart).join(" ");

	const [hover, setHover] = useState(false); // State to manage hover effect

	// Styles for the Link component
	const linkStyle = {
		textDecoration: 'none',
		fontSize: '20px',
		padding: '10px 20px',
		border: hover? '#f8d12c' :'2px solid white',
		borderRadius: '5px',
		backgroundColor: hover ? '#2d324a' : 'transparent',
		color: hover ? '#f8d12c' : 'white',
		transition: '0.3s ease-in-out'
	};

	const handleMouseEnter = () => {
		setHover(true);
	};

	const handleMouseLeave = () => {
		setHover(false);
	};


	return (
		<div className='banner'>
			<img src={image} alt='banner' />
			<div className='banner__overlay'></div>
			<div className='banner__content'>
				<h1>
					{outOfSpan} <span>{banerSpan}</span>
				</h1>
				<Link 
					to="/account"
					style={linkStyle}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
				>
					{subtitle}
				</Link>
			</div>
		</div>
	);
}

export default Banner;

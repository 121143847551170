// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { AbsenceBlock, MessageThread, Message, Task, PatronProfile, WorkerProfile, GigwizUser } = initSchema(schema);

export {
  AbsenceBlock,
  MessageThread,
  Message,
  Task,
  PatronProfile,
  WorkerProfile,
  GigwizUser
};
import React from "react";
import brianImage from "../../assets/images/brian.png";
import aniImage from "../../assets/images/ani.png";
import mercyImage from "../../assets/images/mercy.png";
import estherImage from "../../assets/images/esther.png";
import selloIMage from "../../assets/images/sello.png";
import TeamCorousel from "./TeamCorousel";

export const team_data = [
	{ id: 1, name: "Brian Mukeswe", title: "Technology", image: brianImage },
	{
		id: 2,
		name: "Esther Mutinda",
		title: "Technology & Administration",
		image: estherImage,
	},
	{ id: 3, name: "Mercy Wanalo", title: "Operations", image: mercyImage },
	{
		id: 4,
		name: "Nkiru Ani",
		title: "Marketing & Communications",
		image: aniImage,
	},
	{
		id: 5,
		name: "Retsepile Sello",
		title: "Strategy & Finance",
		image: selloIMage,
	},
];
// Co-Founder
function Team() {
	return (
		<div className='team'>
			<h2 className='team__title'>Meet the Team</h2>
			<div className='team__container'>
				{team_data.map((member) => (
					<div key={member.id} className='member'>
						<img src={member.image} alt={member.name} />
						<div className='member__info'>
							<h3 className='member__name'>
								{member.name} <span>Co-Founder</span>
							</h3>
							<p className='member__title'>{member.title}</p>
						</div>
					</div>
				))}
				<div className='team__container--corousel'>
					<TeamCorousel />
				</div>
			</div>
		</div>
	);
}

export default Team;

import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { BsEnvelopeFill } from "react-icons/bs";

function RequestPasswordReset({ logIngPageRedirect }) {
	const [email, setEmail] = useState('');
 	const [message, setMessage] = useState('');
	const [showResetForm, setShowResetForm] = useState(false);
	const [code, setCode] = useState('');
  	const [newPassword, setNewPassword] = useState('');

	const handleSubmitRequest = async (e) => {
		e.preventDefault();
		try {
		  await Auth.forgotPassword(email);
		  setMessage('Password reset code has been sent to '+ email);
		  setShowResetForm(true);

		} catch (error) {
		  setMessage(error.message);
		}
	  };

	  const handleSubmitReset = async (e) => {
		e.preventDefault();
		try {
		  await Auth.forgotPasswordSubmit(email, code, newPassword);
		  setMessage('Password has been successfully reset.');
		} catch (error) {
		  setMessage(error.message);
		}
	  };
	

	return (
		<div className='auth-page-container'>
			<h2>Reset Your Password</h2>
			<form onSubmit={handleSubmitRequest}>
				<div className='form-group'>
					<BsEnvelopeFill />
					<input
						type='email'
						className='form-control'
						id='email'
						placeholder='Enter email'
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						required
					/>
				</div>
				<button className='btn btn-primary'>Send Reset Code</button>
			</form>
			<div className='auth-page-container__links'>
				<p onClick={logIngPageRedirect}>
					<u> Sign In Instead</u>
				</p>
			</div>
			{showResetForm && (
				<div>
				<div>{message}</div>
				<form onSubmit={handleSubmitReset}>
				  <label>Code:</label>
				  <input
					type="text"
					value={code}
					onChange={(e) => setCode(e.target.value)}
					required
				  />
				  <label>New Password:</label>
				  <input
					type="password"
					value={newPassword}
					onChange={(e) => setNewPassword(e.target.value)}
					required
				  />
				  <button type="submit">Reset Password</button>
				</form>
				
			  </div>
			)}
		</div>
	);
}

export default RequestPasswordReset;

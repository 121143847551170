import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { navLinks } from "../navLinks";
import { useGigWizContext } from "../../../contexts/Gigwiz";

function Mobile() {
	const { showMobile, handlehowMobile, isLoggedIn } = useGigWizContext();
	const location = useLocation();
	const navigate = useNavigate();
	const handleNavigate = (path) => {
		navigate(path);
		handlehowMobile();
	};

	return showMobile ? (
		<div className='mobile-menu'>
			<div className='mobile-menu__links-container'>
				<ul>
					{location.pathname === "/"
						? navLinks.slice(1).map((entry) => (
								<li
									className={
										entry.link === "My Account" ? "account-tab" : undefined
									}
									onClick={() =>
										entry.link === "My Account" && isLoggedIn
											? handleNavigate("/account")
											: handleNavigate(entry.path)
									}
									key={entry.link}
								>
									{entry.link}
								</li>
						  ))
						: navLinks.map((entry) => (
								<li
									className={
										entry.link === "My Account" ? "account-tab" : undefined
									}
									onClick={() =>
										entry.link === "My Account" && isLoggedIn
											? handleNavigate("/account")
											: handleNavigate(entry.path)
									}
									key={entry.link}
								>
									{entry.link}
								</li>
						  ))}
				</ul>
			</div>
		</div>
	) : null;
}

export default Mobile;

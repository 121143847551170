import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RiCopyrightFill } from "react-icons/ri";
import moment from "moment";
import { navLinks, socialIcons } from "../navLinks";

const footerData = {
	description:
		"GigWiz is an online labour marketplace connecting service seekers (Patrons) to service providers (Wizes) in the vast Kenyan informal labour market. Our platform lets you hire a Wiz at the touch of a button!",
	links: {
		title: "Quick Links",
		entries: navLinks,
	},
};
export const currentYear = moment().format("YYYY");

function Footer() {
	const navigation = useNavigate();
	const location = useLocation();

	return location.pathname.includes("/profile") ? null : (
		<div className='gigwiz-footer'>
			<div className='gigwiz-footer__content'>
				<div className='footer-entry'>
					<div className='footer-entry__description'>
						<p>{footerData?.description}</p>
						<div className='footer-entry__social'>
							{socialIcons?.map((icon) => (
								<span className='icon' key={icon.id}>
									{icon.icon}
								</span>
							))}
						</div>
					</div>
				</div>
				<div className='footer-entry'>
					<div>
						<p style={{ marginBottom: "15px" }}>
							<span>{footerData?.links.title.split(" ")[0].toUpperCase()}</span>{" "}
							{footerData?.links.title.split(" ")[1].toUpperCase()}
						</p>
						{footerData?.links.entries.map((entry) => (
							<p
								className='footer-entry__link'
								onClick={() => navigation(entry.path)}
								key={entry.link}
							>
								{entry.link}
							</p>
						))}
					</div>
				</div>
				
			</div>
			<div className='gigwiz-footer__copyright'>
				<RiCopyrightFill className='gigwiz-footer__copyright--icon' />
				<p>{currentYear} GigWiz | All Rights Reserved</p>
			</div>
		</div>
	);
}

export default Footer;

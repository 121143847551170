import React, { useEffect } from "react";
import { getCurrentUser } from "../../contexts/auth";
import { DataStore } from '@aws-amplify/datastore';
import { GigwizUser } from "../../models";



function TaskDetails({ nextStep }) {

	const [taskDetails, setTaskDetails] = React.useState({
		taskCategory: "",
		taskDescription: "",
		taskLocation: "",
		taskStartDate: "",
	});
	const [taskInfoError, setTaskInfoError] = React.useState({
		taskCategory: false,
		taskDescription: false,
		taskLocation: false,
		taskStartDate: false,
	});

	const [patronUserObj, setPatronUserObj] = React.useState();
	React.useEffect(() => {
		async function getUserData() {
			const curr = getCurrentUser();
			const resultArray = await DataStore.query(
				GigwizUser, 
				c => c.email.eq(curr.email)
			);

			setPatronUserObj(resultArray[0]);
		}
		getUserData();
	});

	const handleCategoryIndex = (entry) => {
		setTaskDetails({
			...taskDetails,
			taskCategory: entry,
		});
	};

	const taskCategories = [
		'Cooking',
		'House Cleaning',
		'Tutoring',
		'Hair Styling',
		'Laundry',
		'Plumbing'
	];
	

	
	const storeTaskDetails = (data) => {
		// store details locally
		localStorage.setItem("taskDetails", JSON.stringify(data));

		console.log(data);
	};

	const handleSaveTask = (taskDetails) => {
		const data = {
			...taskDetails,
			patronName: patronUserObj?.first_name,
			patronId: patronUserObj?.id,
			assignedWizName:  '',
			assignedWizId: '',
			taskEstimatedCost: 1000.0,
			taskStatus: 'requested'
		};
		console.log(data);
		storeTaskDetails(data);
	}

	// check that none of the values in task details is null
	useEffect(() => {
		if (Object.values(taskDetails).every(value => value !== "")) {
			handleSaveTask(taskDetails);
		}
	}, [taskDetails]);

	const handleShowTAskInfoError = (data) => {
		const { taskCategory, taskLocation, taskStartDate } = data;
		setTaskInfoError({
			taskCategory: taskCategory === "" ? true : false,
			taskLocation: taskLocation === "" ? true : false,
			taskStartDate: taskStartDate === "" ? true : false,
		});
	};


	return (
		<section className='task-details'>
			<form>
				<div className='form-group'>
					<label for='task-category'>Select Task Category</label><br />
					<select style={{width: '100%'}} 
							onChange={(e) => {
							handleCategoryIndex(e.target.value);
							handleShowTAskInfoError(taskDetails);
						}} name='' id='task-category'>
						<option value=''>Select the Task Category</option>
						{taskCategories.map((category, index) => (
							<option key={index} value={category}>
								{category}
							</option>
						))}
					</select>
				</div>
				<div className='form-group'>
				<label for='task-description'>Please Describe the Problem you Want Solved</label><br />
					<textarea
						name=''
						id='task-description'
						cols='45'
						placeholder={`${taskDetails.taskCategory} Task Description`}
						onChange={(e) =>
							setTaskDetails({
								...taskDetails,
								taskDescription: e.target.value,
							})}
						value={taskDetails.taskDescription}
						rows='3'
						style={{width: '100%'}}
					></textarea>
				</div>
				<div className='form-group'>
					<label htmlFor='date'>Select date(s) to get task done</label>
					<input
						className='date'
						type='date'
						onChange={(e) => {
							setTaskDetails({
								...taskDetails,
								taskStartDate: e.target.value,
							});
							handleShowTAskInfoError(taskDetails);
						}}
						value={taskDetails.taskStartDate}
						placeholder='Select date(s) to get task done'
						style={{width: '100%'}}
					/>
					{taskInfoError.taskStartDate && (
						<span
							style={{
								color: "red",
								fontSize: "1.3rem",
								fontWeight: "bold",
							}}
						>
							Task Date is required
						</span>
					)}
				</div>
				<div className='form-group'>
				<label htmlFor='address'>Enter Task Location</label>
					<textarea
						type='text'
						onChange={(e) => {
							setTaskDetails({
								...taskDetails,
								taskLocation: e.target.value,
							});
							handleShowTAskInfoError(taskDetails);
						}}
						value={taskDetails.taskLocation}
						id='address'
						placeholder='Enter Task Location'
						style={{width: '100%'}}
					/>
					{taskInfoError.taskLocation && (
						<span
							style={{
								color: "red",
								fontSize: "1.3rem",
								fontWeight: "bold",
							}}
						>
							Task Location is required
						</span>
					)}
				</div>

			</form>
			
		</section>
	);
}

export default TaskDetails;

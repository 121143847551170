import React from 'react'
import HireWizComponent from '../../components/Hire-Wiz/HireWizComponent'

function HireWiz() {
  return (
    <div>
      <HireWizComponent/>
    </div>
  )
}

export default HireWiz
import React from "react";
import { leftSideData } from "../MainProfileComponent";
import { AiFillCopyrightCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { currentYear } from "../../Common/Footer/Footer";
import TopBarDesktop from "../Top Bar/TopBarDesktop";
import TopBarMobile from "../Top Bar/TopBarMobile";
import MobileMenu from "../Mobile Menu/MobileMenu";
import { useGigWizContext } from "../../../contexts/Gigwiz";
import SideBar from "../Sidebar/SideBar";
import JobsMobileLanding from "./MobileTabs/JobsPageMobileLanding";
import Calendar from "../Calendar/Calendar";
import { getUserInfo, isLoggedIn} from '../../../contexts/auth';
import Account from "../../MyAccountPageComponent/Account";
import { API, graphqlOperation } from "aws-amplify";
import { listTasks } from "../../../graphql/queries";



function Jobs() {
	const navigate = useNavigate();
	const { showUserProfileMenu } = useGigWizContext();
	const [gigRequests, setGigRequests] = React.useState();
	const [pendingOrders, setPendingOrders] = React.useState();

	async function fetchTasks(){
		try {
			
			const userInfo = await getUserInfo();

			// Fetch gig requests
			const response = await API.graphql(graphqlOperation(listTasks, {
				filter: {
					assignedWizId: { eq: userInfo.id },
					taskStatus: {eq: 'requested'}
				  }
			}));
			setGigRequests(response.data.listTasks.items);

			// Fetch orders
			const ordersResponse = await API.graphql(graphqlOperation(listTasks, {
				filter: {
					patronId: { eq: userInfo.id },
					taskStatus: {eq: 'requested'}
				  }
			}));
			setPendingOrders(ordersResponse.data.listTasks.items);

		  } catch (error) {
			console.error('Error fetching Tasks:', error);
		  }
	};

	React.useEffect(() => {
		fetchTasks();
	  }, []);


	return (
		isLoggedIn()?
		<section className='user-profile-section'>
			{showUserProfileMenu && <MobileMenu leftSideData={leftSideData} />}
			<div className='user-profile-section__container'>
				<TopBarDesktop />
				<TopBarMobile />
				<div className='user-profile-section__container--main'>
					<SideBar />
					<div className='user-profile-section__container--main__right-side'>
						<div className='user-profile-section__container--main__right-side--main-content'>
							<div className='user-profile-section__container--main__right-side--main-content__container'>
								<div className='jobs'>
									<div className='jobs__header'>
										<h2>Jobs</h2>
									</div>
									<div className='jobs__body'>
										<JobsMobileLanding
											gigs_link='/profile/jobs/my-gigs'
											orders_link='/profile/jobs/orders'
											gigRequests={gigRequests}
											pendingOrders={pendingOrders}
										/>
										<div className='jobs__body--my-gigs gig-container'>
											<div className='gig-container__header'>
												<h3>My Gigs</h3>
											</div>
											<div className='gig-container__gig-distributions'>
												<p>Gig Requests</p>
												<p>{gigRequests? gigRequests.length:0}</p>
											</div>
											
											<div
												onClick={() => navigate("/profile/jobs/my-gigs")}
												className='gig-container__browse-button'
											>
												<p>Browse</p>
											</div>
										</div>
										<div className='jobs__body--orders gig-container'>
											<div className='gig-container__header'>
												<h3>Orders</h3>
											</div>

											<div className='gig-container__gig-distributions'>
												<p>Pending Orders</p>
												<p>{pendingOrders? pendingOrders.length:0}</p>
											</div>
											

											<div
												onClick={() => navigate("/profile/jobs/orders")}
												className='gig-container__browse-button'
											>
												<p>Browse</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className='user-profile-section__container--main__right-side--footer'>
							<p>
								<AiFillCopyrightCircle /> {currentYear} GigWiz | All Rights
								Reserved
							</p>
						</div>
					</div>
				</div>
			</div>
			<Calendar />
		</section>
		: <div>
			<Account />
		</div>
	);
}

export default Jobs;

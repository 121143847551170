import React, { useState } from 'react';

function FileUpload({ onFileChange, childKey }) {
  const handleFileChange = (e) => {
    if (onFileChange) {
      onFileChange(e.target.files[0]);
    }
  };

  return (
    <input
      type="file"
      accept="image/*"
      onChange={handleFileChange}
      key={ 'input_'+childKey }
    />
  );
}

export default FileUpload;

import {useState} from "react";
import {Auth} from "aws-amplify";
import { BsEnvelopeFill } from "react-icons/bs";
import { FaUnlockAlt } from "react-icons/fa";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { setUser, isLoggedIn, getCurrentUser, logout} from '../../contexts/auth';

function SignIn({
	passwordResetPageRedirect,
	registerPageRedirect,
	handleShowPassword,
	showPassword,
}) {

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const navigate = useNavigate();
	const signIn = async (e) => {
		
		try {
			e.preventDefault()
			const user = await Auth.signIn(username, password);

			/*
			const response = await API.graphql(graphqlOperation(listGigwizUsers, {
				filter: {
					email: { eq: user.email }
				  }
			}));
			*/
			
			const userInfo = {
				...user.attributes,
				username: user.username
			};
			
      		setUser(userInfo);
			console.log('sign in successful');
			console.log(userInfo);
      		navigate("/profile/jobs");

		} catch (error) {
			console.log('error signing in', error);
		}
	}

	return (
		isLoggedIn()? 
			
			<div className='auth-page-container'>
				<h1>
				<a href="profile/jobs">Continue as {getCurrentUser().given_name}</a>
				</h1>

				<div className='user-profile-section__container--main__left-side--navigation__sign-out'>
				<p onClick={ () => {
						logout()
						navigate('/account')
				}} style={{ color: "black", textDecoration: "underline"}}>
						Sign Out
				</p>
				</div>
			</div>
			
			:<div className='auth-page-container'>
				<h2>Sign In To Your Account</h2>
				<form>
					<div className='form-group'>
						<BsEnvelopeFill />{" "}
						<input
							type='text'
							className='form-control'
							id='username'
							placeholder='Enter your username'
							onChange={(e) => 
								setUsername(e.target.value)
							}
							value={username}
						/>
					</div>
					<div className='form-group'>
						<FaUnlockAlt />
						<input
							type={showPassword ? "text" : "password"}
							className='form-control'
							id='password'
							placeholder='Enter password'
							onChange={(e) => 
								setPassword(e.target.value)
							}
							value={password}
						/>
						<div onClick={handleShowPassword} className='show-password'>
							{showPassword ? (
								<AiFillEyeInvisible title='Hide Password' />
							) : (
								<AiFillEye title='Show Password' />
							)}
						</div>
					</div>
					<button
						onClick={(e) =>
							signIn(e)
						}
						className='btn btn-primary'
					>
						Sign In
					</button>
				</form>
				<div className='auth-page-container__links'>
					<p onClick={passwordResetPageRedirect}>Forgot Password?</p>
					<p onClick={registerPageRedirect}>
						Don't Have an Account? <u> Register</u>
					</p>
				</div>
			</div>
	);
}

export default SignIn;

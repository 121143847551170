import React from "react";
import { useGigWizContext } from "../../../contexts/Gigwiz";
import { DataStore } from '@aws-amplify/datastore';
import { getCurrentUser } from "../../../contexts/auth";
import { GigwizUser } from "../../../models";

function CTAS({
	prevStep,
	nextStep,
	step,
	taskDetails,
	handlePayment,
	storeTaskData,
	handleShowTAskInfoError,
}) {
	const { selectedWiz } = useGigWizContext();
	const [patronUserObj, setPatronUserObj] = React.useState();
	React.useEffect(() => {
		async function getUserData() {
			const curr = getCurrentUser();
			const resultArray = await DataStore.query(
				GigwizUser, 
				c => c.email.eq(curr.email)
			);

			setPatronUserObj(resultArray[0]);
		}
		getUserData();
	});
	const checkIfTaskIsComplete = () => {
		if (step === 0) {
			if (
				taskDetails?.category !== "" &&
				taskDetails?.taskStartDate !== "" &&
				taskDetails?.taskLocation !== ""
			) {
				nextStep();
			} else {
				handleShowTAskInfoError(taskDetails);
			}
		} else if (step === 1) {
			if (selectedWiz !== null) {
				const data = {
					...taskDetails,
					patronName: patronUserObj?.first_name,
					patronId: patronUserObj?.id,
					assignedWizName:  selectedWiz?.first_name,
					assignedWizId: selectedWiz.id,
					taskEstimatedCost: 1000.0,
					taskStatus: 'requested'
				};
				storeTaskData(data);
				nextStep();
			}
		} else if (step === 2) {
			if (selectedWiz !== null)
				handlePayment(
					Intl.NumberFormat("en-US", {
						style: "currency",
						currency: "KES",
					}).format(1000),
					selectedWiz?.first_name
				);
		}
	};
	return (
		<div className='source-talent__content--buttons-container'>
			<div className='previous-button'>
				<button onClick={() => (step > 0 ? prevStep() : null)}>Previous</button>
			</div>
			<div className='next-button'>
				<button onClick={checkIfTaskIsComplete}>
					{step === 2
						? `Pay ${Intl.NumberFormat("en-US", {
								style: "currency",
								currency: "KES",
						  }).format(1000)}`
						: "Continue"}
				</button>
			</div>
		</div>
	);
}

export default CTAS;

import {Amplify} from 'aws-amplify';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
	LandingPage,
	AboutPage,
	HireWiz,
	BecomeWiz,
	UserAccount,
} from "./pages";
import { Navigation, Footer, MobileMenu } from "./components/Common";
import SkillsPreview from "./components/SkillsPreview/Skills";
import {
	SetAvailability,
	Billing,
	Jobs,
	Orders,
	MyGigs,
	Notifications,
	WizDetailsUpdate,
	HireTalent,
	BillDetail,
} from "./components/User Profile";
import awsconfig from './aws-exports';
import ConfirmSignup from './components/AuthenticationPages/ConfirmSignup';

Amplify.configure(awsconfig);

function App() {
	
	return (
		<Router>
			<div className='app'>
				<Navigation />
				<MobileMenu />
				<Routes>
					<Route path='/' element={<LandingPage />} />
					<Route path='about-us' element={<AboutPage />} />
					<Route path='hire-talent' element={<HireWiz />} />
					<Route path='wiz-join' element={<BecomeWiz />} />
					<Route path='account' element={<UserAccount />} />
					<Route path='confirm' element={<ConfirmSignup />} />
					{/* <Route path='profile' element={<UserProfile />} /> */}
					<Route path='profile/jobs' element={<Jobs />} />
					<Route path='profile/jobs/my-gigs' element={<MyGigs />} />
					<Route path='profile/jobs/orders' element={<Orders />} />
					<Route path='profile/billing' element={<Billing />} />
					<Route path='profile/billing/:billId' element={<BillDetail />} />
					<Route path='profile/details-update' element={<WizDetailsUpdate />} />
					<Route
						path='profile/wiz-availability'
						element={<SetAvailability />}
					/>
					<Route path='profile/hire-wiz' element={<HireTalent />} />
					<Route path='profile/notifications' element={<Notifications />} />
					<Route path='hire-talent/:skill' element={<SkillsPreview />} />
				</Routes>
				<Footer />
			</div>
		</Router>
	);
}

export default App;

import React from "react";
import { leftSideData } from "../MainProfileComponent";
import { AiFillCopyrightCircle } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { currentYear } from "../../Common/Footer/Footer";
import { IoIosArrowBack as BackIcon } from "react-icons/io";
import { useGigWizContext } from "../../../contexts/Gigwiz";
import TopBarDesktop from "../Top Bar/TopBarDesktop";
import TopBarMobile from "../Top Bar/TopBarMobile";
import MobileMenu from "../Mobile Menu/MobileMenu";
import SideBar from "../Sidebar/SideBar";
import Calendar from "../Calendar/Calendar";

function BillDetail() {
	const navigate = useNavigate();
	const formatCurrency = Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "KES",
	});
	const { showUserProfileMenu } = useGigWizContext();
	const params = useParams();
	const billId = params.billId;
	const transactionInfo = JSON.parse(localStorage.getItem('transactionInfo')).filter(item => item.id == billId);

	console.log(transactionInfo);


	const billDetails = transactionInfo[0];
	const total_amount = billDetails?.transactions
		.map((bill) => bill.amount)
		.reduce((a, b) => a + b, 0);

	return (
		<section className='user-profile-section'>
			{showUserProfileMenu && <MobileMenu leftSideData={leftSideData} />}
			<Calendar/>
			<div className='user-profile-section__container'>
				<TopBarDesktop />
				<TopBarMobile />
				<div className='user-profile-section__container--main'>
					<SideBar />
					<div className='user-profile-section__container--main__right-side'>
						<div className='user-profile-section__container--main__right-side--main-content'>
							<div className='user-profile-section__container--main__right-side--main-content__container'>
								<div className='bill-detail-section'>
									<div className='bill-detail-section__container'>
										<div className='bill-detail-section__container--header'>
											<div
												onClick={() => navigate("/profile/billing")}
												className='bill-detail-section__container--header__back-btn'
											>
												<BackIcon />
												<p>Back to Bills</p>
											</div>
										</div>
										<div className='bill-detail-section__container--body'>
											<div className='bill-detail-section__container--body__bill-info'>
												<div className='bill-detail-section__container--body__bill-info--bill-info-header'>
													<div className='bill-info-header-content'>
														<p>Pos</p>
														<p>Description</p>
														<p>Amount</p>
													</div>
													<div className='underline'></div>
												</div>
												<div className='bill-detail-section__container--body__bill-info--bill-info-body'>
													{billDetails?.transactions.map((task, index) => {
														const {
															id,
															amount,
															date,
															category,
															patron
														} = task;
														return (
															<div key={id} className='bill-info-body-content'>
																<p>{++index}.</p>
																<p>
																	<span>{date}</span>
																	{" - "}<span>{category} </span>
																	{" for "} <span>{patron}</span>{" "}
																</p>
																<p>{formatCurrency.format(amount)}</p>
															</div>
														);
													})}
												</div>
												<div className='underline'></div>
												<div className='total-bill-amount'>
													<p>Net Amount</p>
													<p>{formatCurrency.format(total_amount)}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className='user-profile-section__container--main__right-side--help'>
							<p> Need Help?</p>
						</div>
						<div className='user-profile-section__container--main__right-side--footer'>
							<p>
								<AiFillCopyrightCircle /> {currentYear} GigWiz | All Rights
								Reserved
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default BillDetail;

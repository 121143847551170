import React, { useEffect, useState } from "react";
import { wizDummy } from "../Home/FeaturedWiz";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import emplImage from "../../assets/images/empl.jpg";

import { BsPersonFill as PersonIcon } from "react-icons/bs";
import {
	FiChevronDown as ArrowDown,
	FiChevronUp as ArrowUp,
	FiSearch as SearchIcon,
} from "react-icons/fi";
import { FaExternalLinkAlt as ExternalLinkIcon } from "react-icons/fa";
import { useGigWizContext } from "../../contexts/Gigwiz";
import { API, graphqlOperation } from 'aws-amplify';
import { listGigwizUsers } from '../../graphql/queries';
import { getUserInfo } from "../../contexts/auth";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "80vw",
	height: "max-content",
	bgcolor: "white",
	display: "flex",
	flexDirection: "column",
	alignItems: "flex-start",
	justifyContent: "flex-start",
	boxShadow: 24,
	p: 4,
};
const cancelStyle = {
	backgroundColor: "#2d324a",
	color: "white",
	border: "none",
	padding: "1rem 3rem",
	fontSize: "1.5rem",
	fontStyle: "normal",
	cursor: "pointer",
	borderRadius: "0.5rem",
	marginRight: "4.5rem",
};
const additionalDetails = {
	width: "100%",
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
};

const imageContainer = {
	width: "100%",
	height: "20vh",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
};
const pastJobs = {
	width: "100%",
	display: "flex",
	justifyContent: "centre",
	alignItems: "center",
	flexWrap: "wrap",
};
const pastJobsEntry = {
	width: "23%",
	height: "27vh",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	flexDirection: "column",
	marginRight: "10px",
};
const pastJobsTitle={
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	fontSize: "1.8rem",
	fontWeight: "bold",
}


function BrowseWizzes({ nextStep, backStep }) {

	const {
		handleShowWizData,
		handleSelectedWiz,
		selectedWiz,
		handleSelectedWizFromList,
		showWizData, handleHideWizData, selectedWizFromList
	} = useGigWizContext();
	const [viewMore, setViewMore] = useState(true);
	const [sortDesc, setSortDesc] = useState(false);
	const [wizzs, setWizzs] = useState(wizDummy);
	const [sortRateDesc, setSortRateDesc] = useState(false);
	const [nameSortValue, setNameSortValue] = useState("ascending");
	const [rateSortValue, setRateSortValue] = useState("ascending");
	const [clickedWiz, setClickedWiz] = useState(false);
	const [searchedWiz, setSearchedWiz] = useState("");
	const handleSetWizzsData = (wizzsData) => {
		handleSelectedWizFromList(wizDummy,wizzsData);
		handleShowWizData();
	};
	const formatCurrency = Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "KES",
	});


	useEffect(() => {
		fetchUsers();
	  }, []);
	
	  async function fetchUsers() {
		try {

			const curr = await getUserInfo();
			const patronId = curr.id; 
			
			const response = await API.graphql(graphqlOperation(listGigwizUsers, {
				filter: {
					id: {ne: patronId}
				}
			}));
			setWizzs(response.data.listGigwizUsers.items);

		} catch (error) {
		  console.error('Error fetching GigwizUsers:', error);
		}
	};

	useEffect(() => {
		if (nameSortValue === "ascending") {
			setSortDesc(true);
		} else {
			setSortDesc(false);
		}
		if (rateSortValue === "ascending") {
			setSortRateDesc(true);
		} else {
			setSortRateDesc(false);
		}
	}, [nameSortValue, rateSortValue]);

	useEffect(() => {
		if (wizzs.length > 0) {
			if (sortDesc) {
				setWizzs(wizzs.sort((a, b) => (b.first_name > a.first_name ? 1 : -1)));
			}
			if (sortRateDesc) {
				setWizzs(wizzs.sort((a, b) => (b.rates > a.rates ? 1 : -1)));
			}
		}
	}, [sortDesc, sortRateDesc, wizzs]);

	const manageWizData = (wiz) => {
		handleSelectedWiz(wiz);

		// update task details in local storage
		const taskDetails = JSON.parse(localStorage.getItem('taskDetails'));
		localStorage.setItem("taskDetails", JSON.stringify({
			...taskDetails,
			assignedWizName: wiz.first_name,
			assignedWizId: wiz.id
		}));
		
		setClickedWiz(null);
	};

	const handleClickedWiz = (index) => {
		if (clickedWiz === index) {
			setViewMore(false);
			return setClickedWiz(null);
		}
		setClickedWiz(index);
		setViewMore(true);
	};

	return (
		<section className='browse-wizzes-section'>
			{showWizData && (
				<Modal
					keepMounted
					open={showWizData}
					onClose={handleHideWizData}
					aria-labelledby='keep-mounted-modal-title'
					aria-describedby='keep-mounted-modal-description'
				>
					<Box  sx={style}>
						<div className="additinal-wiz-data-box" style={imageContainer}>
							<img
								src={selectedWizFromList.image}
								alt={`${selectedWizFromList.first_name}`}
								style={{ objectFit: "contain", height: "100%" }}
							/>
						</div>
						<div style={additionalDetails} className='wiz-additonal-details'>
							<div className='wiz-additonal-details__data'>
								<h2>
									Name: {"  "} {selectedWizFromList?.first_name}
								</h2>
								<h2>Occupation: {selectedWizFromList?.occupation}</h2>
								<h2>Location: {selectedWizFromList?.location}</h2>
							</div>
							<div className='wiz-additonal-details__data'>
								<h2>
									Ratings:{" "}
									<Rating
										name='half-rating-read'
										value={selectedWizFromList?.rating}
										precision={0.5}
										style={{ marginLeft: "10px" }}
										readOnly
									/>
								</h2>
								<h2>Rate: {formatCurrency.format(selectedWizFromList?.rates)} Per Task</h2>
							</div>
						</div>
						<div style={pastJobsTitle}>
							<h2>Past Jobs</h2>
						</div>
						<div style={pastJobs}>
							{selectedWizFromList?.past_jobs.slice(0, 4).map((job) => (
								<div
									style={pastJobsEntry}
									key={job.id}
									className='past-jobs__data'
								>
									<img
										src={job.picture}
										alt={`${job.title}-${job.job_location}`}
										style={{ height: "100%", width: "100%" }}
									/>
								</div>
							))}
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								marginTop: "1.5rem",
								width: "100%",
							}}
							className='buttons-container'
						>
							<button style={cancelStyle} onClick={handleHideWizData}>
								Close
							</button>
						</div>
					</Box>
				</Modal>
			)}
			<div className='browse-wizzes-section__filters'>
				<p>
					<select
						onChange={(e) => setNameSortValue(e.target.value)}
						name='filter-by-name'
						id='filter-by-name'
					>
						<option value=''>Sort By Names</option>
						<option value='ascending'>Sort By Name(s) A-Z</option>
						<option value='descending'>Sort By Name(s) Z-A</option>
					</select>
				</p>
				<p className='search-wiz'>
					<input
						value={searchedWiz}
						onChange={(e) => setSearchedWiz(e.target.value)}
						type='text'
						placeholder='Search Wiz By Name or Location'
					/>
					<SearchIcon />
				</p>
				<p>
					<select
						onChange={(e) => setRateSortValue(e.target.value)}
						name='filter-by-rates'
						id='filter-by-rates'
					>
						<option value=''>Sort By Rates (KSHS)</option>
						<option value='ascending'>Lowest to Highest</option>
						<option value='descending'>Highest to Lowest</option>
					</select>
				</p>
			</div>

			<div className='browse-wizzes-section__wizzes-list'>
				<ul>
					{wizzs
						.filter((wiz) =>
							wiz.first_name.toLowerCase().includes(searchedWiz.toLowerCase())
								? wiz
								: null
						)
						?.map((wiz, index) => {
							const { id, first_name, rating, occupation, rates, image, city } =
								wiz;
							return (
								<div key={id}>
									<li
										className={clickedWiz === index ? "" : "entry-margin"}
										onClick={() => handleClickedWiz(index)}
										style={selectedWiz?.id === id? {backgroundColor:'#f8d12c'}: {}}
									>
										<p>
											<PersonIcon className='person-icon' />
										</p>
										<p>{first_name}</p>
										<p className='occupation'>{occupation}</p>
										<p>KSHS. {'1,000'} Per Task</p>
										<p className='rating'>
											<Rating
												name='half-rating-read'
												value={rating}
												precision={0.5}
												style={{ marginLeft: "10px" }}
												readOnly
											/>
										</p>
										<p className='arrows'>
											{viewMore && clickedWiz === index  ? <ArrowUp /> : <ArrowDown />}
										</p>
									</li>
									{clickedWiz === index ? (
										<div className='browse-wizzes-section__wizzes-list--show-more'>
											<div className='browse-wizzes-section__wizzes-list--show-more__image-container'>
												<img src={emplImage} alt={`${first_name}`} />
											</div>
											<div className='browse-wizzes-section__wizzes-list--show-more__details'>
												<div className='browse-wizzes-section__wizzes-list--show-more__details--left-side'>
													<div>
														<p>Name:</p>
														<p> {first_name}</p>
													</div>
													<div>
														<p>Location:</p>
														<p>{city}</p>
													</div>
													
												</div>
												<div className='browse-wizzes-section__wizzes-list--show-more__details--right-side'>
													<div>
														<p>Rating:</p>
														<p>
															<Rating
																name='half-rating-read'
																value={rating}
																precision={0.5}
																style={{ marginLeft: "10px" }}
																readOnly
															/>
														</p>
													</div>
													<div>
														<p>Rate:</p>
														<p>KSHS. {'1,000'} Per Task</p>
													</div>
													
												</div>
											</div>
											<div className='browse-wizzes-section__wizzes-list--show-more__button-container'>
												<button
													onClick={() => manageWizData(wiz)}
													className={
														clickedWiz === index && selectedWiz?.id === id
															? "disabled"
															: "browse-wizzes-section__wizzes-list--show-more__button-container--button"
													}
												>
													<p>
														{clickedWiz === index && selectedWiz?.id === id
															? "You've Selected This Wiz"
															: "Hire this Wiz"}
													</p>
												</button>
											</div>
										</div>
									) : null}
								</div>
							);
						})}
				</ul>
			</div>
			<div className='browse-wizzes-section__buttons'>
				{backStep && (
					<button
						className='browse-wizzes-section__buttons--back'
						onClick={backStep}
					>
						Previous
					</button>
				)}
				{nextStep && (
					<button
						className={
							selectedWiz
								? "browse-wizzes-section__buttons--next"
								: "browse-wizzes-section__buttons--next disabled"
						}
						onClick={nextStep}
					>
						Continue
					</button>
				)}
			</div>
		</section>
	);
}

export default BrowseWizzes;

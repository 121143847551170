import React from "react";

function SectionTitle({ titleClass, title }) {
	return (
		<div className={titleClass}>
			<h3>{title}</h3>
			<div></div>
		</div>
	);
}

export default SectionTitle;

import React from "react";
import {
	FiChevronDown as ArrowDown,
	FiChevronUp as ArrowUp,
} from "react-icons/fi";
import { API, graphqlOperation } from 'aws-amplify';
import { listTasks } from '../../../../../graphql/queries';
import { formatDate } from "../../../../utilities/Util-Functions";
import {  getUserInfo } from "../../../../../contexts/auth";
import { updateTask } from "../../../../../graphql/mutations";


function UpcomingOrders({openChat}) {
	const [showGigInfo, setShowGigInfo] = React.useState(false);
	const [upcomingGigs, setUpcomingGigs] = React.useState([]);
	const handleShowGigInfo = (index) => {
		if (showGigInfo === index ) {
			return setShowGigInfo(null);
		}
		setShowGigInfo(index);
	};

	const sortedGigs = [...upcomingGigs].sort((a, b) => {
		const dateA = new Date(a.createdAt);
		const dateB = new Date(b.createdAt);
		return dateA - dateB;
	  });



	const handleCancelJob = async (id) => {
		try {
		  const selectedTask = upcomingGigs.find(item => item.id === id); 
	  
		  // check if "selectedTask" is found
		  if (!selectedTask) {
			throw new Error('Task not found');
		  }
		  
		  const toUpdate = {
			id: selectedTask.id,
			_version: selectedTask._version,
			taskStatus: "patron-cancelled"
		  }
		  
		  const updatedTask = await API.graphql(graphqlOperation(updateTask, {
			input: toUpdate
		  }));


		} catch (error) {
		  // Log the error for debugging
		  console.error('Error:', error);
		}
	  };

	async function fetchTasks(){
		try {
			const userInfo = await getUserInfo();
			const patronId = userInfo.id; 

			const response = await API.graphql(graphqlOperation(listTasks, {
				filter: {
					patronId: { eq: patronId },
					taskStatus: {eq: 'requested'}
				  }
			}));

			setUpcomingGigs(response.data.listTasks.items);
			

		  } catch (error) {
			console.error('Error fetching Tasks:', error);
		  }
	};

	React.useEffect(() => {
		fetchTasks();
	  }, []);

	return (
		<div className='entries-container'>
			{sortedGigs?.map((entry, index) => {
				const {
					taskCategory,
					taskDescription,
					taskLocation,
					taskStartDate,
					assignedWizName,
					id,
					updatedAt
				} = entry;
				return (
					<div
						key={id}
						onClick={() => handleShowGigInfo(index)}
						className='entries-container__element'
					>
						<div
							className={
								showGigInfo === index
									? "entries-container__element--data hide-item-contents"
									: "entries-container__element--data"
							}
						>
							
							<p>{taskCategory}</p>
							<p>{assignedWizName}</p>
							<p>{taskLocation}</p>
							<p>{formatDate(taskStartDate)}</p>
							{showGigInfo === index ? <ArrowUp /> : <ArrowDown />}
						</div>
						{showGigInfo === index ? (
							<div className='entries-container__element--expanded'>
								<div className='entries-container__element--expanded__entry'>
									<p>Job ID:</p>
									<p>{id}</p>
								</div>
								<div className='entries-container__element--expanded__entry'>
									<p>Job Category:</p>
									<p>{taskCategory}</p>
								</div>
								<div className='entries-container__element--expanded__entry'>
									<p>Job Description:</p>
									<p>{taskDescription}</p>
								</div>
								<div className='entries-container__element--expanded__entry'>
									<p>Job Location:</p>
									<p>{taskLocation}</p>
								</div>
								<div className='entries-container__element--expanded__entry'>
									<p>Assigned Wiz:</p>
									<p>
										{assignedWizName}
									</p>
								</div>
								<div className='entries-container__element--expanded__entry'>
									<p>Job Start Date:</p>
									<p>
										{formatDate(taskStartDate)}
									</p>
								</div>
							
								<div className='entries-container__element--expanded__buttons-container'>
									<button className='entries-container__element--expanded__buttons-container--button cancel-job'
											onClick={() => {handleCancelJob(id)}}>
										Cancel Job
									</button>
								</div>
							</div>
						) : null}
					</div>
				);
			})}
		</div>
	);
}

export default UpcomingOrders;

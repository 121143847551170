import React from "react";
import { useNavigate } from "react-router-dom";

function JobsMobileLanding({ gigs_link, orders_link, gigRequests, pendingOrders }) {
	const navigate = useNavigate();

	return (
		<div className='jobs__body--mobile-landing'>
			<div className='jobs__body--mobile-landing__my-gigs'>
				<h3>My Gigs</h3>
				<div className='jobs__body--mobile-landing__orders--counters'>
					<p>Gig Requests <span>{gigRequests? gigRequests.length:0}</span></p>
					
				</div>
				<div
					onClick={() => navigate(gigs_link)}
					className='jobs__body--mobile-landing__my-gigs--browse-button'
				>
					<p>Browse</p>
				</div>
			</div>
			<br></br>
			<div className='jobs__body--mobile-landing__orders'>
				<h3>Orders</h3>
				<div className='jobs__body--mobile-landing__orders--counters'>
					<p>Pending Orders <span>{pendingOrders? pendingOrders.length:0}</span></p>
				</div>
				<div
					onClick={() => navigate(orders_link)}
					className='jobs__body--mobile-landing__my-gigs--browse-button'
				>
					<p>Browse</p>
				</div>
			</div>
			
		</div>
	);
}

export default JobsMobileLanding;

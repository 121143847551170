import React from "react";
import Banner from "../Common/Banner/Banner";
import UserImage from "../../assets/images/Black Electician.jpg";


const bannerTitle = "Monetize Your Skills And Attain Financial Freedom.";
const bannerSubtitle = "Get started";


function BecomeWizComponent() {
	
	return (
		<div>
			<Banner
				image={UserImage}
				bannerStart={4}
				subtitle={bannerSubtitle}
				title={bannerTitle}
			/>
		</div>
	);
}

export default BecomeWizComponent;

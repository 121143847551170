import {Auth} from "aws-amplify";
import { listGigwizUsers } from "../graphql/queries";
import { API, graphqlOperation } from 'aws-amplify';


const isBrowser = typeof window !== `undefined`

export const setUser = user => (window.localStorage.setItem('user', JSON.stringify(user)))

const fetchUserInfo = async (user) => {
  try {
    const response = await API.graphql(graphqlOperation(listGigwizUsers, {
      filter: {
        email: { eq: user.email }
        }
    }));
    return response.data.listGigwizUsers.items[0]

  } catch (error){
    console.log('error fetching user info', error);
  }
}

export const getUserInfo = () => {
  if (window.localStorage.getItem('user')) {
    let user = JSON.parse(window.localStorage.getItem('user'))
    return fetchUserInfo(user)
  }
  return {}
}

const getUser = () => {
  
  if (window.localStorage.getItem('user')) {
    let user = JSON.parse(window.localStorage.getItem('user'))

    return user ? user : {}
  }
  return {}
}

export const isLoggedIn = () => {
  if (!isBrowser) return false

  const user = getUser()
  if (user) return !!user.username
}

export const getCurrentUser = () => isBrowser && getUser()

const signOut = async () => {
  
  try {
    await Auth.signOut();
  } catch (error) {
    console.log('error signing out: ', error);
  }
};

export const logout = () => {
  if (!isBrowser) return
  signOut()
  setUser({})
}
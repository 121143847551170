import React from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";
import {
	BsPersonFill as PersonIcon,
	BsFillCalendar2DateFill as Calendar,
} from "react-icons/bs";
import { ImLocation2 as Pin } from "react-icons/im";
import { RiFocus2Line as OccupationIcon } from "react-icons/ri";
import employee from "../../assets/images/cleaner.jpg";
import cleaner from "../../assets/images/plumber.jpg";
import wiz from "../../assets/images/chef.jpg";
import electrician from "../../assets/images/Black Electician.jpg";
import FeaturedWizCorousel from "./FeaturedWizCorousel";
import Pagination from "../Hire-Wiz/Pagination";



export const wizDummy = [
	{
		id: 1,
		first_name: "Jane",
		location: "Kilimani, Nairobi, Kenya",
		occupation: "Cleaner",
		availability: "Available Immediately",
		rating: 1,
		rates: 1500,
		past_jobs: [
			{
				id: 1,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 2,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 3,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 4,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 5,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 6,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 7,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 8,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 9,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 10,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 11,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
		],
		image: employee,
	},
	{
		id: 2,
		first_name: "Allan",
		location: "Mihango, Nairobi, Kenya",
		occupation: "Plumber",
		availability: "Available Immediately",
		rating: 1,
		rates: 1000,
		past_jobs: [
			{
				id: 1,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 2,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 3,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 4,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 5,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 6,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 7,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 8,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 9,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 10,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 11,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
		],
		image: cleaner,
	},
	{
		id: 3,
		first_name: "Murithi",
		location: "Kileleshwa, Nairobi, Kenya",
		occupation: "Chef",
		availability: "Available Immediately",
		rating: 1,
		rates: 1100,
		past_jobs: [
			{
				id: 1,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 2,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 3,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 4,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 5,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 6,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 7,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 8,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 9,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 10,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 11,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
		],
		image: wiz,
	},
	{
		id: 4,
		first_name: "Kirimo",
		location: "Kitengela, Kajiado, Kenya",
		occupation: "Electrician",
		availability: "Available Immediately",
		rating: 1,
		rates: 2000,
		past_jobs: [
			{
				id: 1,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 2,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 3,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 4,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 5,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 6,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 7,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 8,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 9,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 10,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
			{
				id: 11,
				title: "Electrical Installation",
				category: "Electrical",
				picture: "https://www.w3schools.com/w3css/img_avatar3.png",
				job_location: "Kileleshwa Nairobi",
			},
		],
		image: electrician,
	},
];

function FeaturedWiz({ showizzesCard, showWiz, hideWiz }) {
	const navigate = useNavigate();
	const handleSubmit = (e) => {
		e.preventDefault();
		navigate(`/account`);
	};

	return (
		<section
			className={
				showizzesCard
					? "featuredWiz-section active-wizzes-view"
					: "featuredWiz-section"
			}
		>
			<div className='featuredWiz-section__title'>
				<h2>Featured Wizzes</h2>
				<p>Below are some of our top rated wizzes</p>
			</div>
			{showizzesCard ? (
				<div className='featuredWiz-section__wizzes-card'>
					<Pagination hideWiz={hideWiz} />
				</div>
			) : (
				<div className='featuredWiz-section__preview'>
					{wizDummy.map((wiz) => {
						const { id, first_name, location, occupation, availability, image } = wiz;
						return (
							<div key={id} className='featuredWiz-section__preview--item'>
								<div className='featuredWiz-section__preview--item__image'>
									<img src={image} alt='wiz' />
								</div>
								<div className='featuredWiz-section__preview--item__info'>
									<div className='name'>
										<p>
											<PersonIcon />
											{first_name}
										</p>
									</div>
									<div className='location'>
										<p>
											<Pin />
											{location}
										</p>
									</div>
									<div className='occupation'>
										<p>
											<OccupationIcon />
											{occupation}
										</p>
									</div>
									<div className='availability'>
										<p>
											<Calendar />
											{availability}
										</p>
									</div>
								</div>
							</div>
						);
					})}
					<div className='featuredWiz-section__preview--corousel'>
						<FeaturedWizCorousel />
					</div>
				</div>
			)}
			{!showizzesCard && (
				<div className='featuredWiz-section__CTA'>
					<button
						onClick={handleSubmit}
						className='featuredWiz-section__CTA--button'
					>
						View All Wizzes
						<AiOutlineArrowRight />
					</button>
				</div>
			)}
		</section>
	);
}

export default FeaturedWiz;

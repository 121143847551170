import React from "react";
import { leftSideData } from "../MainProfileComponent";
import { AiFillCopyrightCircle } from "react-icons/ai";
import { currentYear } from "../../Common/Footer/Footer";
import TopBarDesktop from "../Top Bar/TopBarDesktop";
import TopBarMobile from "../Top Bar/TopBarMobile";
import MobileMenu from "../Mobile Menu/MobileMenu";
import { useGigWizContext } from "../../../contexts/Gigwiz";
import SideBar from "../Sidebar/SideBar";
import Calendar from "../Calendar/Calendar";
import VerticalLinearStepper from "../../Hire-Wiz/MobileStepper";
// import CustomizedSteppers from "../../Hire-Wiz/Stepper";
import SourceTalent from "../../SourceTalent/SourceTalent";

function HireTalent() {
	const { showUserProfileMenu } = useGigWizContext();
	return (
		<section className='user-profile-section'>
			{showUserProfileMenu && <MobileMenu leftSideData={leftSideData} />}
			<Calendar />
			<div className='user-profile-section__container'>
				<TopBarDesktop />
				<TopBarMobile />
				<div className='user-profile-section__container--main'>
					<SideBar />
					<div className='user-profile-section__container--main__right-side'>
						<div className='user-profile-section__container--main__right-side--main-content'>
							<div className='user-profile-section__container--main__right-side--main-content__container'>
								{/* <section className='avaiable-wizzes-section'>
									<div className='avaiable-wizzes-section__desktop'>
										<CustomizedSteppers />
									</div>
									<div
										style={{ paddingLeft: "2vh" }}
										className='avaiable-wizzes-section__mobile'
									>
										<VerticalLinearStepper />
									</div>
								</section> */}
								<div className='hire-wiz'>
									<div className="hire-wiz__mobile-container">
										<VerticalLinearStepper />
									</div>
									<div className='hire-wiz__container'>
										<SourceTalent />
									</div>
								</div>
							</div>
						</div>

						<div className='user-profile-section__container--main__right-side--footer'>
							<p>
								<AiFillCopyrightCircle /> {currentYear} GigWiz | All Rights
								Reserved
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default HireTalent;

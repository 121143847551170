import React from "react";
import Account from "../../components/MyAccountPageComponent/Account";

function UserAccount() {
	return <div>
		<Account/>
	</div>;
}

export default UserAccount;

import React, { useEffect, useState } from "react";
import { leftSideData } from "../MainProfileComponent";
import { AiFillCopyrightCircle } from "react-icons/ai";
import { BiCheckCircle } from "react-icons/bi";
import { currentYear } from "../../Common/Footer/Footer";
import TopBarDesktop from "../Top Bar/TopBarDesktop";
import TopBarMobile from "../Top Bar/TopBarMobile";
import MobileMenu from "../Mobile Menu/MobileMenu";
import { useGigWizContext } from "../../../contexts/Gigwiz";
import SideBar from "../Sidebar/SideBar";
import axios from "../../../axios";
import Loading from "../../../Loader";
import Calendar from "../Calendar/Calendar";

function Notifications() {
	const { showUserProfileMenu } = useGigWizContext();
	const [notifications, setNotifications] = useState([]);

	useEffect(() => {
		async function getUserData() {
			const {
				data: {
					user: {
						details: { notifications_group },
					},
				},
			} = await axios.get();

			setNotifications(notifications_group);
			return notifications_group;
		}
		getUserData();
	}, []);
	console.log(notifications);
	return (
		<section className='user-profile-section'>
			{showUserProfileMenu && <MobileMenu leftSideData={leftSideData} />}
			<div className='user-profile-section__container'>
				<TopBarDesktop />
				<TopBarMobile />
				<div className='user-profile-section__container--main'>
					<SideBar />
					<div className='user-profile-section__container--main__right-side'>
						<div className='user-profile-section__container--main__right-side--main-content'>
							<div className='user-profile-section__container--main__right-side--main-content__container'>
								<div className='notifications'>
									<div className='notifications__header'>
										<h2>Notifications</h2>
									</div>
									<div className='notifications__underline'></div>
									{notifications?.length < 1 ? (
										<div className='notifications__no-notifications'>
											<Loading />
											</div>
									) : (
										<div className='notifications__entries-container'>
											{notifications.map((notification) => {
												const { id, category, message, time, viewed } =
													notification;
												return (
													<div
														key={id}
														className={
															category === "My Gigs" && viewed === false
																? "notifications__entries-container--entry gig-entry"
																: category === "Orders" && viewed === false
																? "notifications__entries-container--entry order-entry"
																: "notifications__entries-container--entry"
														}
													>
														<p>{category}</p>
														<p>{id}</p>
														<p>{message}</p>
														<p>{time}</p>

														{viewed ? (
															<p style={{ color: "green" }}>
																<BiCheckCircle />
															</p>
														) : (
															<p>
																<BiCheckCircle />
															</p>
														)}
													</div>
												);
											})}
										</div>
									)}
								</div>
							</div>
						</div>

						<div className='user-profile-section__container--main__right-side--help'>
							<p> Need Help?</p>
						</div>
						<div className='user-profile-section__container--main__right-side--footer'>
							<p>
								<AiFillCopyrightCircle /> {currentYear} GigWiz | All Rights
								Reserved
							</p>
						</div>
					</div>
				</div>
			</div>
			<Calendar />
		</section>
	);
}

export default Notifications;

import { FaFacebook, FaLinkedin, FaTwitter, FaInstagram } from "react-icons/fa";

export const navLinks = [
	{ link: "Home", path: "/" },
	{ link: "About", path: "/about-us" },
	{ link: "Hire Wiz", path: "/hire-talent" },
	{ link: "Become A Wiz", path: "/wiz-join" },
	{ link: "My Account", path: "/account" },
];

export const socialIcons = [
	{
		id: 1,
		icon: <FaInstagram />,
		url: "#",
	},
	{
		id: 2,
		icon: <FaTwitter />,
		url: "#",
	},
	{
		id: 3,
		icon: <FaFacebook />,
		url: "#",
	},
	{
		id: 4,
		icon: <FaLinkedin />,
		url: "#",
	},
];
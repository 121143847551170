/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://qnrqiwpmtfelxipmch64jm6ji4.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-vsi5zmior5btzjvrpxohwy6y2q",
    "aws_cloud_logic_custom": [
        {
            "name": "gigwizrestapi",
            "endpoint": "https://3quwqf07od.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-2:4ebd7bc1-fdb9-4fdb-89a4-fe40022b5eac",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_pXxDDqfUZ",
    "aws_user_pools_web_client_id": "67epc471d7o4c0ioucr95vrccu",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "PHONE_NUMBER",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "gigwiz2-storage-94f76eb0171801-dev",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;

import React, { useState } from "react";
import { ImLocation2 as Pin } from "react-icons/im";
import {
	BiArrowFromLeft as ArrowRight,
	BiArrowFromRight as ArrowLeft,
} from "react-icons/bi";

export const data = [
	{
		id: 1,
		name: "John Doe",
		occupation: "Electrician",
		skills: ["Skill 1", "Skill 2", "Skill 3"],
		ratings: 4.5,
		location: "Nairobi",
		image: "https://www.w3schools.com/w3images/avatar2.png",
	},
	{
		id: 2,
		name: "John Doe",
		occupation: "Mechanic",
		skills: ["Skill 1", "Skill 2", "Skill 3"],
		ratings: 4.5,
		location: "Nairobi",
		image: "https://www.w3schools.com/w3images/avatar2.png",
	},
	{
		id: 3,
		name: "John Doe",
		occupation: "Plumber",
		skills: ["Skill 1", "Skill 2", "Skill 3"],
		ratings: 4.5,
		location: "Nairobi",
		image: "https://www.w3schools.com/w3images/avatar2.png",
	},
	{
		id: 4,
		name: "John Doe",
		occupation: "Technical Support",
		skills: ["Skill 1", "Skill 2", "Skill 3"],
		ratings: 4.5,
		location: "Nairobi",
		image: "https://www.w3schools.com/w3images/avatar2.png",
	},
	{
		id: 5,
		name: "John Doe",
		occupation: "Technical Support",
		skills: ["Skill 1", "Skill 2", "Skill 3"],
		ratings: 4.5,
		location: "Nairobi",
		image: "https://www.w3schools.com/w3images/avatar2.png",
	},

];

const renderWizes = (data) => {
	return (
		<>
			{data.map((wiz) => {
				const { id, name, occupation, skills, location, image } = wiz;
				return (
					<div key={id} className='avaiable-wizzes-section__content--item'>
						<div className='avaiable-wizzes-section__content--item__image'>
							<img src={image} alt='wiz' />
						</div>
						<div className='avaiable-wizzes-section__content--item__details'>
							<h3>{name}</h3>
							<p>{occupation} </p>
							<div className='skills'>
								<ul>
									{skills.map((skill, index) => (
										<li key={index}>{skill}</li>
									))}
									<li>+3 More</li>
								</ul>
							</div>
							<p className='location ratings-space'>Ratings Placeholder</p>
							<p className='location'>
								{<Pin />}
								{location}
							</p>
							<div className='action-buttons'>
								<button className='btn btn-primary'>View Profile</button>
								<button className='btn btn-primary'>Hire Wiz</button>
							</div>
						</div>
					</div>
				);
			})}
		</>
	);
};

function Pagination() {
	const [currentPage, setcurrentPage] = useState(1);
	const [itemsPerPage] = useState(3);

	const [pageNumberLimit] = useState(3);
	const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(3);
	const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

	const handleClick = (event) => {
		setcurrentPage(Number(event.target.id));
	};

	const pages = [];
	for (let i = 1; i <= Math.ceil(data.length / itemsPerPage); i++) {
		pages.push(i);
	}

	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

	const renderPageNumbers = pages.map((number) => {
		if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
			return (
				<li
					key={number}
					id={number}
					onClick={handleClick}
					className={currentPage === number ? "active" : undefined}
				>
					{number}
				</li>
			);
		} else {
			return null;
		}
	});

	const handleNextbtn = () => {
		setcurrentPage(currentPage + 1);

		if (currentPage + 1 > maxPageNumberLimit) {
			setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
			setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
		}
	};

	const handlePrevbtn = () => {
		setcurrentPage(currentPage - 1);

		if ((currentPage - 1) % pageNumberLimit === 0) {
			setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
			setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
		}
	};

	let pageIncrementBtn = null;
	if (pages.length > maxPageNumberLimit) {
		pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
	}

	let pageDecrementBtn = null;
	if (minPageNumberLimit >= 1) {
		pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
	}

	return (
		<>
			<div className='avaiable-wizzes-section__content'>
				{renderWizes(currentItems)}
			</div>
			<div className='available-wizzes-section__pagination'>
				<ul className='pageNumbers'>
					<p>
						Page {currentPage} of {pages.length}{" "}
					</p>
					{currentPage > 1 && (
						<li>
							<button onClick={handlePrevbtn}>
								<ArrowLeft />
							</button>
						</li>
					)}
					{pageDecrementBtn}
					{renderPageNumbers}
					{pageIncrementBtn}

					{currentPage < pages.length && (
						<li>
							<button onClick={handleNextbtn}>
								<ArrowRight />
							</button>
						</li>
					)}
				</ul>
			</div>
		</>
	);
}

export default Pagination;

import React from "react";
import {
	FeaturedWiz,
	Hero,
	UserCategory,
} from "../../components/Home";

function LandingPage() {
	const [showizzes, setShowizzes] = React.useState(false);
	const handleShowizzes = () => {
		setShowizzes(true);
	};
	const handleHideizzes = () => {
		setShowizzes(false);
	};
	return (
		<div>
			<Hero />
			<UserCategory />
			<FeaturedWiz
				showizzesCard={showizzes}
				showWiz={handleShowizzes}
				hideWiz={handleHideizzes}
			/>
		</div>
	);
}

export default LandingPage;

import React from "react";
import storyImage from "../../assets/images/phone-image.png";

function OurStory() {
	return (
		<div className='about'>
			<div className='about__our-story'>
				<h2 className='about__our-story-title'>Our Story</h2>
				<p className='about__our-story-text'>
				We came together to challenge how work is found, done and shared on the African continent.
				Our team brings together inviduals from diverse backgrounds to create a platform that is redefining the approach to work.  
				We value fair compensation for skills and services so that workers can earn a living wage that unlocks economic freedom. 

				</p>
			</div>
			<div className='about__image-container'>
				<img src={storyImage} alt='Our Story' className='about__image-container--image' />
			</div>
		</div>
	);
}

export default OurStory;

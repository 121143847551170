import React from "react";
import { BsCalendarDateFill as AvailablityIcon } from "react-icons/bs";
import { AiFillCopyrightCircle } from "react-icons/ai";
import { FiActivity as JobsIcon } from "react-icons/fi";
import { FaHandshake as HireWizIcon } from "react-icons/fa";
import { IoIosNotifications as NotificationsIcon } from "react-icons/io";
import {
	BiDollarCircle as BillIcon,
	BiBookReader as OpenToJobsIcon,
} from "react-icons/bi";
import { currentYear } from "../Common/Footer/Footer";
import TopBarDesktop from "./Top Bar/TopBarDesktop";
import TopBarMobile from "./Top Bar/TopBarMobile";
import MobileMenu from "./Mobile Menu/MobileMenu";
import { useGigWizContext } from "../../contexts/Gigwiz";
import SideBar from "./Sidebar/SideBar";
import Calendar from "./Calendar/Calendar";

export const leftSideData = [
	// {
	// 	id: 1,
	// 	icon: <DashboardIcon />,
	// 	title: "Dashboard",
	// 	path: "/profile",
	// },
	{
		id: 2,
		icon: <JobsIcon />,
		title: "Jobs",
		path: "/profile/jobs",
	},
	{
		id: 3,
		icon: <HireWizIcon />,
		title: "Hire A Wiz",
		path: "/profile/hire-wiz",
	},
	{
		id: 4,
		icon: <OpenToJobsIcon />,
		title: "Become A Wiz",
		path: "/profile/details-update",
	},
	{
		id: 5,
		icon: <AvailablityIcon />,
		title: "Set Availability",
		path: "/profile/details-update",
	},
	{
		id: 6,
		icon: <BillIcon />,
		title: "Billing",
		path: "/profile/billing",
	},
	//{
	//	id: 7,
	//	icon: <NotificationsIcon />,
	//	title: "Notifications",
	//	path: "/profile/notifications",
	//},
];

function MainProfileComponent() {
	const { showUserProfileMenu } = useGigWizContext();
	return (
		<section className='user-profile-section'>
			{showUserProfileMenu && <MobileMenu leftSideData={leftSideData} />}
			<div className='user-profile-section__container'>
				<TopBarDesktop />
				<TopBarMobile />
				<div className='user-profile-section__container--main'>
					<SideBar />
					<div className='user-profile-section__container--main__right-side'>
						<div className='user-profile-section__container--main__right-side--main-content'>
							<div className='user-profile-section__container--main__right-side--main-content__container'>
								<div className='dash-board'>
									<div></div>
									<p>NO RUNNING ACTIVITIES</p>
									<div></div>
								</div>
							</div>
						</div>
						<div className='user-profile-section__container--main__right-side--footer'>
							<p>
								<AiFillCopyrightCircle /> {currentYear} GigWiz | All Rights
								Reserved
							</p>
						</div>
					</div>
				</div>
			</div>
			<Calendar />
		</section>
	);
}

export default MainProfileComponent;

import React, { useState } from "react";
import {
	BsClockFill as Convenience,
	BsCheckCircleFill as QualityAssurance,
} from "react-icons/bs";
import { FaHandshake as Reliability } from "react-icons/fa";
import { GiReceiveMoney as WizPayment } from "react-icons/gi";
import { AiFillEye as Exposure } from "react-icons/ai";
import {
	FiChevronDown as ArrowDown,
	FiChevronUp as ArrowUp,
} from "react-icons/fi";
import employer from "../../assets/images/employer.jpg";
import employee from "../../assets/images/Wiz.jpg";
import { useNavigate } from "react-router-dom";

const userCategory = [
	{
		id: 12,
		name: "employer",
		title: "Our guarantee to our patrons",
		image: employer,
		benefits: [
			{
				id: 1,
				symbol: <Convenience />,
				title: "Convenience",
				description:
					"Quickly hire a Wiz at any time for any duration, hustle free!",
				pointer: <ArrowDown />,
			},
			{
				id: 2,
				symbol: <Reliability />,
				title: "Reliability",
				description:
					"Avoid being over charged for services. Pay a fair & consistent price.",
				pointer: <ArrowDown />,
			},
			{
				id: 3,
				symbol: <QualityAssurance />,
				title: "Quality Assurance",
				description:
					"Our matching technology will find the Wiz who is best suited for you.",
				pointer: <ArrowDown />,
			},
		],
		redirect: "/hire-talent",
		buttonText: "Hire A Wiz",
	},
	{
		id: 13,
		name: "wiz",
		title: "The Gigwiz Guarantee to our Wizzes:",
		image: employee,
		benefits: [
			{
				id: 1,
				symbol: <WizPayment />,
				title: "Take Control of Your Earning Power",
				description: "Get compensated for your work at a competitive rate and on your own terms.",
				pointer: <ArrowDown />,
			},
			{
				id: 2,
				symbol: <Exposure />,
				title: "Exposure",
				description:
					"Leverage your skills and increase market visibility of your skill and service, whether it is your main or a side-hustle. You also get competitive insights.",
				pointer: <ArrowDown />,
			},
			{
				id: 3,
				symbol: <Convenience />,
				title: "Convenience",
				description: "Accept gigs only when it suits your schedule.",
				pointer: <ArrowDown />,
			},
		],
		redirect: "/wiz-join",
		buttonText: "Become A Wiz",
	},
];

function UserCategory() {
	const [clicked, setClicked] = useState(false);
	const [employerClick, setEmployerClick] = useState(false);
	const navigate=useNavigate()

	const handleClick = (index) => {
		if (clicked === index) {
			return setClicked(null);
		}
		setClicked(index);
	};
	const handleEmployerClick = (index) => {
		if (employerClick === index) {
			return setEmployerClick(null);
		}
		setEmployerClick(index);
	};
	const {
		title: employerCat,
		image: employerImg,
		benefits: employerBenefits,
		name: employer,
		redirect: employerRedirect,
		buttonText: employerButtonText,
	} = userCategory[0];

	const {
		title: employeeCat,
		image: employeeImg,
		benefits: employeeBenefits,
		name: employee,
		buttonText: employeeButton,
		redirect: employeeRedirect,
	} = userCategory[1];
	return (
		<section className='user-category-section'>
			<div className='user-category-section__user'>
				<div className='user-details'>
					<h4>{employerCat}</h4>
					{employerBenefits.map((benefit, index) => {
						return (
							<div
								onClick={() => handleEmployerClick(index)}
								key={index}
								className='user-details__benefit'
							>
								<div className='user-details__benefit--icon'>
									<div className='symbol'>{benefit.symbol}</div>
									<h5>{benefit.title}</h5>
								<div className='pointer'>{employerClick === index ? <ArrowUp /> : benefit.pointer}</div>
								</div>
								<div className='user-details__benefit--text'>
									{employerClick === index ? <p>{benefit.description}</p> : null}
								</div>
							</div>
						);
					})}
					<div className='user-category-section__CTA'>
						<button onClick={()=>navigate(employerRedirect)} className='user-category-section__CTA--button'>{employerButtonText} </button>
					</div>
				</div>
				<div className='user-image'>
					<img src={employerImg} alt={`${employer}-avator`} />
				</div>
			</div>

			<div className='user-category-section__user'>
				<div className='user-details'>
					<h4>{employeeCat}</h4>
					{employeeBenefits.map((benefit, index) => {
						return (
							<div
								onClick={() => handleClick(index)}
								key={index}
								className='user-details__benefit'
							>
								<div className='user-details__benefit--icon'>
									<div className='symbol'>{benefit.symbol}</div>
									<h5>{benefit.title}</h5>
								<div className='pointer'>{clicked === index ? <ArrowUp /> : benefit.pointer}</div>
								</div>
								<div className='user-details__benefit--text'>
									{clicked === index ? <p>{benefit.description}</p> : null}
								</div>
							</div>
						);
					})}
				<div className='user-category-section__CTA'>
						<button onClick={()=>navigate(employeeRedirect)} className='user-category-section__CTA--button'>{employeeButton} </button>
					</div>
				</div>
				<div className='user-image'>
					<img src={employeeImg} alt={`${employee}-avator`} />
				</div>
			</div>
		</section>
	);
}

export default UserCategory;

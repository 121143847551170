import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components/macro";
import { VscArrowLeft, VscArrowRight } from "react-icons/vsc";
import { wizDummy } from "./FeaturedWiz";
import {
	BsPersonFill as PersonIcon,
	BsFillCalendar2DateFill as Calendar,
} from "react-icons/bs";
import { ImLocation2 as Pin } from "react-icons/im";
import { RiFocus2Line as OccupationIcon } from "react-icons/ri";

const FeaturedWizCorouselSection = styled.section`
	height: 400px;
	width: 300px;
	position: relative;
	overflow: hidden;
	margin-top: 3rem;
	@media screen and (max-width: 768px) {
		margin-top: 0.5rem;
	}
`;

const CorouselWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
`;
const CorouselEntry = styled.div`
	z-index: 1;
	width: 100%;
	height: 100%;
`;
const CorouselImage = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 80%;
	object-fit: cover;
`;
const CorouselEntryInfo = styled.div`
	position: relative;
	z-index: 10;
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: auto;
	color: #fff;
	padding: 1.2rem 0 0.5rem 1rem;
	background: #fff;
	height: calc(100% / 3);
	h3 {
		color: #000d1a;
		font-size: 1.8rem;
		font-weight: 800;
	}
	p {
		color: #000d1a;
		font-size: 1.5rem;
		font-weight: 700;
		svg {
			margin-right: 0.5rem;
		}
	}
	span {
		font-size: 1.5rem;
		font-weight: 600;
		color: #000d1a;
		margin-left: 2rem;
		font-style: italic;
	}
`;
const CorouselItem = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const SliderButtons = styled.div`
	position: absolute;
	width: 100%;
	bottom: 45%;
	padding: 0 5px 0 5px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 6;
`;
const arrowButtons = css`
	width: 40px;
	height: 40px;
	color: #fff;
	cursor: pointer;
	background: #000d1a;
	border-radius: 50px;
	padding: 5px;
	margin-right: 1rem;
	user-select: none;
	transition: 0.3s;
	&:hover {
		transform: scale(1.05);
	}
`;
const PrevArrow = styled(VscArrowLeft)`
	${arrowButtons}
`;
const NextArrow = styled(VscArrowRight)`
	${arrowButtons}
`;
const FeaturedWizCorousel = () => {
	const [current, setCurrent] = useState(0);
	const timeout = useRef(null);
	const length = wizDummy.length;
	useEffect(() => {
		const nextSlider = () => {
			setCurrent((current) => (current === length - 1 ? 0 : current + 1));
		};
		timeout.current = setTimeout(nextSlider, 10000);
		return function () {
			if (timeout.current) {
				clearTimeout(timeout.current);
			}
		};
	}, [current, length]);

	const nextSlide = () => {
		if (timeout.current) {
			clearTimeout(timeout.current);
		}
		setCurrent(current === length - 1 ? 0 : current + 1);
	};
	const prevSlide = () => {
		if (timeout.current) {
			clearTimeout(timeout.current);
		}
		setCurrent(current === 0 ? length - 1 : current - 1);
	};

	return (
		<FeaturedWizCorouselSection>
			<CorouselWrapper>
				{wizDummy.map((wiz, index) => {
					return (
						<CorouselEntry key={index}>
							{index === current && (
								<CorouselItem>
									<CorouselImage src={wiz.image} alt={wiz.first_name} />
									<CorouselEntryInfo>
										<div>
											<p>
												<PersonIcon /> {wiz.first_name}
											</p>
										</div>
										<div>
											<p>
												<Pin /> {wiz.location}
											</p>
										</div>
										<div>
											<p>
												<OccupationIcon />
												{wiz.occupation}
											</p>
										</div>
										<div>
											<p>
												<Calendar />
												{wiz.availability}
											</p>
										</div>
									</CorouselEntryInfo>
								</CorouselItem>
							)}
						</CorouselEntry>
					);
				})}
				<SliderButtons>
					<PrevArrow onClick={prevSlide} />
					<NextArrow onClick={nextSlide} />
				</SliderButtons>
			</CorouselWrapper>
		</FeaturedWizCorouselSection>
	);
};
export default FeaturedWizCorousel;

import React from "react";
import { leftSideData } from "../../MainProfileComponent";
import { IoIosArrowBack as BackIcon } from "react-icons/io";
import { AiFillCopyrightCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { currentYear } from "../../../Common/Footer/Footer";
import { useState } from "react";
import UpcomingOrders from "./Upcoming Orders/UpcomingOrders";
import OrdersInProgress from "./Orders In Progress/OrdersInProgress";
import FulfilledOrders from "./Fulfilled Orders/FulfilledOrders";
import ConfirmedOrders from "./Confirmed Orders/ConfirmedOrders";
import TopBarDesktop from "../../Top Bar/TopBarDesktop";
import TopBarMobile from "../../Top Bar/TopBarMobile";
import MobileMenu from "../../Mobile Menu/MobileMenu";
import { useGigWizContext } from "../../../../contexts/Gigwiz";
import MbileTabs from "../MobileTabs/MbileTabs";
import SideBar from "../../Sidebar/SideBar";
import Chat from "../../Chat/Chat";
import Calendar from "../../Calendar/Calendar";

const titles = [
	"Pending Orders",
	"Confirmed Orders",
	"Orders In Progress",
	"Fulfilled Orders",
];

function Orders() {
	const navigate = useNavigate();
	const { showUserProfileMenu } = useGigWizContext();
	const [clickedTitle, setClickedTitle] = useState(0);
	const [showChat, setShowChat] = useState(false);
	const [selectedJob, setSelectedJob] = useState();
	const handleClick = (index) => {
		setClickedTitle(index);
	};
	const handleChat = (task) => {
		setSelectedJob(task);
		setShowChat(true);
	};
	const handleCloseChat = () => {
		setShowChat(false);
	};
	return (
		<section className='user-profile-section'>
			{showUserProfileMenu && <MobileMenu leftSideData={leftSideData} />}
			<div className='user-profile-section__container'>
				<TopBarDesktop />
				<TopBarMobile />
				<div className='user-profile-section__container--main'>
					<SideBar />
					<div className='user-profile-section__container--main__right-side'>
						<div className='user-profile-section__container--main__right-side--main-content'>
							<div className='user-profile-section__container--main__right-side--main-content__container'>
								<div className='my-orders'>
									<div className='my-orders__header'>
										<div
											onClick={() => {
												handleCloseChat();
												navigate("/profile/jobs");
											}}
											className='my-orders__header--back-button'
										>
											<BackIcon />
											<p>Back to Jobs</p>
										</div>
										<h2>Orders</h2>
									</div>
									<div className='my-orders__content'>
										<div className='my-orders__content--titles'>
											{titles?.map((item, index) => {
												return (
													<p
														className={
															clickedTitle === index
																? "active-title"
																: undefined
														}
														onClick={() => {
															handleCloseChat();
															handleClick(index);
														}}
														key={item}
													>
														{item}
													</p>
												);
											})}
										</div>
										<MbileTabs
											classProp='my-orders__content--mobile-tabs'
											handleClick={handleClick}
											closeChat={handleCloseChat}
											tabData={titles}
										/>
										<div className='my-orders__content--underline'></div>
										<div className='my-orders__content--content'>
											{clickedTitle === 0 ? (
												<UpcomingOrders openChat={(job) => {handleChat(job)}} />
											) : clickedTitle === 1 ? (
												<ConfirmedOrders openChat={(job) => {handleChat(job)}} />
											) : clickedTitle === 2 ? (
												<OrdersInProgress openChat={(job) => {handleChat(job)}} handleClick={handleClick} />
											) : (
												<FulfilledOrders />
											)}
										</div>
									</div>
									{showChat && <Chat closeChat={handleCloseChat}  selectedJob={selectedJob}/>}
								</div>
							</div>
						</div>

						
						<div className='user-profile-section__container--main__right-side--footer'>
							<p>
								<AiFillCopyrightCircle /> {currentYear} GigWiz | All Rights
								Reserved
							</p>
						</div>
					</div>
				</div>
			</div>
			<Calendar/>
		</section>
	);
}

export default Orders;

import React from "react";
import { leftSideData } from "../MainProfileComponent";
import { AiFillCopyrightCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { currentYear } from "../../Common/Footer/Footer";
import mpseaIcon from "../../../assets/images/mpsea-icon.png";
import { useGigWizContext } from "../../../contexts/Gigwiz";
import TopBarDesktop from "../Top Bar/TopBarDesktop";
import TopBarMobile from "../Top Bar/TopBarMobile";
import MobileMenu from "../Mobile Menu/MobileMenu";
import SideBar from "../Sidebar/SideBar";
import Calendar from "../Calendar/Calendar";
import { API, graphqlOperation } from 'aws-amplify';
import { listTasks } from "../../../graphql/queries";
import { getUserInfo } from "../../../contexts/auth";

function Billing() {
	const navigate = useNavigate();
	const { showUserProfileMenu } = useGigWizContext();
	const formatCurrency = Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "KES",
	});

	const NoBillingData = () => {
		return (
			<p className='billing__body--billing-history__group--category-body__item--no-data'>
				You don't have any payments yet
			</p>
		);
	};

	function extractMonthFromDate(dateString) {
		const months = [
		  'January', 'February', 'March', 'April',
		  'May', 'June', 'July', 'August',
		  'September', 'October', 'November', 'December'
		];
	  
		const date = new Date(dateString);
		const month = months[date.getMonth()];
		const year = date.getFullYear();
	  
		return `${month} ${year}`;
	  };

	  function getPastSixMonths() {
		const months = [
		  'January', 'February', 'March', 'April',
		  'May', 'June', 'July', 'August',
		  'September', 'October', 'November', 'December'
		];
	  
		const today = new Date();
		const result = [];
	  
		for (let i = 0; i < 6; i++) {
		  const month = months[today.getMonth()];
		  const year = today.getFullYear();
		  result.push(`${month} ${year}`);
	  
		  // Move back one month for the next iteration
		  if (today.getMonth() === 0) {
			// If it's January, go back to December of the previous year
			today.setFullYear(today.getFullYear() - 1);
			today.setMonth(11); // December is 11th month
		  } else {
			today.setMonth(today.getMonth() - 1);
		  }
		}
		return result;
	}

	const [pendingPayments, setPendingPayments] = React.useState([]);
	const [historicalPayments, setHistoricalPayments] = React.useState([]);

	const compileHistoricalPayments = () => {
		const transactionInfo = [];
		const pastMonths = getPastSixMonths();
		const completedTasks = JSON.parse(localStorage.getItem('completedTasks'));

		pastMonths.forEach((month, index) => {
			const monthTasks = completedTasks.filter(task => extractMonthFromDate(task.taskStartDate) == month);
			const monthTransactions = [];
			monthTasks.forEach(item => {
				monthTransactions.push({
					id: item.id,
					category: item.taskCategory,
					patron: item.patronName,
					date: item.taskStartDate,
					amount: item.taskEstimatedCost,
					month: extractMonthFromDate(item.taskStartDate)
				})
			});

			transactionInfo.push({
				id: index,
				month: month,
				transactions: monthTransactions
			})
		})
		
		localStorage.setItem("transactionInfo", JSON.stringify(transactionInfo));
		console.log(transactionInfo);

		setHistoricalPayments(transactionInfo);
	};

	async function fetchTasks(){
		try {
			const userInfo = await getUserInfo();

			const response = await API.graphql(graphqlOperation(listTasks, {
				filter: {
					assignedWizId: { eq: userInfo.id },
					taskStatus: {eq: 'completed'}
				  }
			}));

			localStorage.setItem("completedTasks", JSON.stringify(response.data.listTasks.items));

		  } catch (error) {
			console.error('Error fetching Tasks:', error);
		  }
	};

	React.useEffect(() => {
		fetchTasks();
		compileHistoricalPayments();
	  }, []);

	//console.log(historicalPayments);

	return (
		<section className='user-profile-section'>
			{showUserProfileMenu && <MobileMenu leftSideData={leftSideData} />}
			<Calendar />
			<div className='user-profile-section__container'>
				<TopBarDesktop />
				<TopBarMobile />
				<div className='user-profile-section__container--main'>
					<SideBar />
					<div className='user-profile-section__container--main__right-side'>
						<div className='user-profile-section__container--main__right-side--main-content'>
							<div className='user-profile-section__container--main__right-side--main-content__container'>
								<div className='billing'>
									<div className='billing__header'>
										<h2>Bills</h2>
									</div>
									<div className='billing__body'>
										<div className='billing__body--account-status'>
											<div className='billing__body--account-status__outstanding-payments'>
												<h3>Outstanding Payments</h3>
												<h4>{formatCurrency.format(0)}</h4>
											</div>
											<div className='billing__body--account-status__payout-method'>
												<img src={mpseaIcon} alt='m-pesa-icon' />
												<p>+254724*****2</p>
											</div>
										</div>
										<div className='billing__body--billing-history'>
											<div className='billing__body--billing-history__group'>
												<div className='billing__body--billing-history__group--category-header'>
													<h3>Outstanding Payments</h3>
													<div></div>
												</div>
												<div className='billing__body--billing-history__group--category-body'>
													<div className='billing__body--billing-history__group--category-body__item'>
														{pendingPayments.length < 1 ? (
															<NoBillingData />
														) : (
															pendingPayments?.map((item) => {
																return (
																	<div
																		key={item.id}
																		onClick={() =>
																			navigate(`/profile/billing/${item.id}`)
																		}
																		className='billing__body--billing-history__group--category-body__item--entry'
																	>
																		<p>{item.title}</p>
																		<p>{formatCurrency.format(item.amount)}</p>
																	</div>
																);
															})
														)}
													</div>
												</div>
											</div>
											<div className='billing__body--billing-history__group'>
												<div className='billing__body--billing-history__group--category-header'>
													<h3>Completed Payments</h3>
													<div></div>
												</div>
												<div className='billing__body--billing-history__group--category-body'>
													<div className='billing__body--billing-history__group--category-body__item'>
														{historicalPayments.length < 1 ? (
															<NoBillingData />
														) : (
															historicalPayments?.map((item) => {

																const { month, transactions} = item;
																const amount = transactions
																	?.map((element) => element.amount)
																	.reduce((a, b) => a + b, 0);
																return (
																	<div
																		key={item.id}
																		onClick={() =>
																			navigate(`/profile/billing/${item.id}`)
																		}
																		className='billing__body--billing-history__group--category-body__item--entry'
																	>
																		<p>{month}</p>
																		<p>{formatCurrency.format(amount)}</p>
																	</div>
																);
															})
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						
						<div className='user-profile-section__container--main__right-side--footer'>
							<p>
								<AiFillCopyrightCircle /> {currentYear} GigWiz | All Rights
								Reserved
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Billing;

import React, { useState } from 'react';
import { Storage } from 'aws-amplify';
import FileUpload from './FileUpload'; 

function UploadForm({promptText, fileName}) {
  const [file, setFile] = useState(null);

  const handleFileChange = (uploadedFile) => {
    setFile(uploadedFile);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      alert('Please select a file to upload.');
      return;
    }

    try {
      const result = await Storage.put(fileName, file, {
        contentType: file.type,
      });
      console.log('File uploaded:', result);
      alert('File uploaded successfully.');
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Failed to upload the file.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>
          <p>{promptText}</p>
        </label>
        <FileUpload onFileChange={handleFileChange} key={'upload_'+fileName} childKey={'upload_'+fileName} />
      </div>
      <button className="" type="submit" key={'submit_button_'+fileName}>
        Save File
      </button>
    </form>
  );
}

export default UploadForm;

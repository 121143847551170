const currentYear = new Date().getFullYear();
const range = (start, stop, step) =>
	Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

const getDaysInMonth = (
	month = new Date().getMonth(),
	year = currentYear - 18
) => {
	const daysCounts = [];
	const daysDetails = new Array(31)
		.fill("")
		.map((v, i) => new Date(year, month - 1, i + 1))
		.filter((v) => v.getMonth() === month - 1);
	for (let day of daysDetails) {
		daysCounts.push(day.getDate());
	}
	return daysCounts;
};
const years = range(currentYear - 18, currentYear - 65, -1);
const months = range(1, 13, 1);
const alphabeticMonths = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December"
];
const getAlphabeticMonths = (month) => alphabeticMonths[month - 1];
const monthsOrdered = (monthsValues = months) => {
	const orderedMonths = [];
	for (let month of monthsValues) {
		orderedMonths.push(getAlphabeticMonths(month));
	}
	return orderedMonths;
};
const baseMonth = new Date().getMonth();
const defaultDay = new Date().getDate();
const theCurrentMonth = monthsOrdered()[baseMonth - 1];

const makeAWSDateString = (year, month, date) => {
	let month_num = null;

	const padNum = (n) => {
		if (parseInt(n) < 10) {
			return `0${n}`;
		}
		else {
			return n.toString();
		}
	}
	for (let m in months){
		if (month === getAlphabeticMonths(m)){
			console.log(m);
			month_num = m;
		}
	}
	//console.log(month);
	//console.log(month_num);
	//console.log(date);
	return `${year}-${padNum(month_num)}-${padNum(date)}Z`
};

export {
	getDaysInMonth,
	years,
	monthsOrdered,
	theCurrentMonth as defaultMonth,
	defaultDay,
	makeAWSDateString,
	getAlphabeticMonths
};

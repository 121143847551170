import React from "react";
import { leftSideData } from "../../MainProfileComponent";
import { IoIosArrowBack as BackIcon } from "react-icons/io";
import { AiFillCopyrightCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { currentYear } from "../../../Common/Footer/Footer";
import { useState } from "react";
import UpcomingGigs from "./Upcoming Gigs/UpcomingGigs";
import GigsInProgress from "./Gigs In Progress/GigsInProgress";
import GigRequests from "./Gig Requests/GigRequests";
import CompletedGigs from "./Completed Gigs/CompletedGigs";
import TopBarDesktop from "../../Top Bar/TopBarDesktop";
import TopBarMobile from "../../Top Bar/TopBarMobile";
import MobileMenu from "../../Mobile Menu/MobileMenu";
import { useGigWizContext } from "../../../../contexts/Gigwiz";
import MbileTabs from "../MobileTabs/MbileTabs";
import SideBar from "../../Sidebar/SideBar";
import Chat from "../../Chat/Chat";
import Calendar from "../../Calendar/Calendar";

const titles = [
	"Gig Requests",
	"Upcoming Gigs",
	"Gigs In Progress",
	"Completed Gigs",
];

function MyGigs() {
	const navigate = useNavigate();
	const [clickedTitle, setClickedTitle] = useState(0);
	const [showChat, setShowChat] = useState(false);
	const [selectedJob, setSelectedJob] = useState();
	const { showUserProfileMenu } = useGigWizContext();
	const handleClick = (index) => {
		setClickedTitle(index);
	};
	const handleChat = (task) => {
		setSelectedJob(task);
		setShowChat(true);
	};
	const handleCloseChat = () => {
		setShowChat(false);
	};
	return (
		<section className='user-profile-section'>
			{showUserProfileMenu && <MobileMenu leftSideData={leftSideData} />}
			<div className='user-profile-section__container'>
				<TopBarDesktop />
				<TopBarMobile />
				<div className='user-profile-section__container--main'>
					<SideBar />
					<div className='user-profile-section__container--main__right-side'>
						<div className='user-profile-section__container--main__right-side--main-content'>
							<div className='user-profile-section__container--main__right-side--main-content__container'>
								<div className='my-gigs'>
									<div className='my-gigs__header'>
										<div
											onClick={() => {
												handleCloseChat();
												navigate("/profile/jobs");
											}}
											className='my-gigs__header--back-button'
										>
											<BackIcon />
											<p>Back to Jobs</p>
										</div>
										<h2>My Gigs</h2>
									</div>
									<div className='my-gigs__content'>
										<div className='my-gigs__content--titles'>
											{titles?.map((item, index) => {
												return (
													<p
														className={
															clickedTitle === index
																? "active-title"
																: undefined
														}
														onClick={() => {handleClick(index); handleCloseChat();}}
														key={item}
													>
														{item}
													</p>
												);
											})}
										</div>
										<MbileTabs
											classProp='my-gigs__content--mobile-tabs'
											handleClick={handleClick}
											closeChat={handleCloseChat}
											tabData={titles}
										/>
										<div className='my-gigs__content--underline'></div>
										<div className='my-gigs__content--content'>
											{clickedTitle === 0 ? (
												<GigRequests handleClick={handleClick}/>
											) : clickedTitle === 1 ? (
												<UpcomingGigs openChat={(job) => {handleChat(job)}} handleClick={handleClick}/>
											) : clickedTitle === 2 ? (
												<GigsInProgress openChat={(job) => {handleChat(job)}} handleClick={handleClick}/>
											) : (
												<CompletedGigs />
											)}
										</div>
									</div>
									{showChat && <Chat closeChat={handleCloseChat} selectedJob={selectedJob}/>}
								</div>
							</div>
						</div>

						
						<div className='user-profile-section__container--main__right-side--footer'>
							<p>
								<AiFillCopyrightCircle /> {currentYear} GigWiz | All Rights
								Reserved
							</p>
						</div>
					</div>
				</div>
			</div>
			<Calendar/>
		</section>
	);
}

export default MyGigs;

import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ItemCard } from '../../../../ui-components';
import UploadForm from './UploadForm';
import WorkerProfileForm from './WorkerProfileForm';
import { DataStore } from '@aws-amplify/datastore';
import { WorkerProfile } from  '../../../../models';



function Skills({userWorkerProfile}){

	async function deleteServiceFromWorkerProfile(profileId, serviceToDelete) {
		try {
		  // Fetch the WorkerProfile object you want to update
		  const profile = userWorkerProfile; 
	  
		  // Remove the value from the services array
		  const newServices = profile.services.filter(service => service !== serviceToDelete);
	  
		  // Update the WorkerProfile object with the new services array
		  const updatedProfile = await DataStore.save(
			WorkerProfile.copyOf(profile, updated => {
			  updated.services = newServices;
			})
		  );
		  alert('success: ' + serviceToDelete + ' removed')
		  console.log('Updated profile:', updatedProfile);
		} catch (error) {
			alert('Error deleting service from worker profile')
		  console.error('Error deleting service from worker profile:', error);
		}
	  }


	return (
		<div className='profile-settings__user-profile-overview'>
		  
			<div className="profile-settings__user-profile-overview--info">
				<WorkerProfileForm/>
			</div>
		
			{userWorkerProfile.services.map((opt, i) => {
					return (
						<div key={i}>
							<h1>__________</h1>
							<ItemCard service={opt}/>
							<div className="profile-settings__user-profile-overview__upload">
									<UploadForm
										promptText={"Optional: Upload a file containing evidence or examples of you offering "+ opt + "  "}
										fileName={opt + "_work_evidence.png"} 
									/>
							</div>
							<br></br>
							<button style={{
								backgroundColor: '#2d324a',
								color: '#f8d12c',
								padding: '5px 8px',
								borderRadius: '5px'
							}} onClick={() => deleteServiceFromWorkerProfile(userWorkerProfile.id, opt)}>delete {opt}</button>
						</div>
					);
			})}

			<br/>
			<br/>

		</div>
	  );
	}
			
export default Skills;

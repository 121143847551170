import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../../assets/images/gigwiz-logo.png";
import { FaBars } from "react-icons/fa";
import { navLinks } from "../navLinks";
import { useGigWizContext } from "../../../contexts/Gigwiz";

export default function Navigation() {
	const location = useLocation();
	const navigation = useNavigate();
	const { handlehowMobile, isLoggedIn } = useGigWizContext();
	const [show, setShow] = useState(false);
	const navigate = useNavigate();

	const navBarTansit = () =>
		window.scrollY > 100 ? setShow(true) : setShow(false);

	useEffect(() => {
		window.addEventListener("scroll", navBarTansit);
		return () => window.removeEventListener("scroll", navBarTansit);
	}, []);

	return location.pathname.includes("/profile") ? null : (
		<div className={show ? "navigation nav-scrolled" : "navigation "}>
			<div
				onClick={() => navigation("/")}
				className='navigation__logo-container'
			>
				<img src={logo} alt='Gigwiz Logo' className='navigation__logo' />
			</div>
			<div className='navigation__links-container'>
				<ul>
					{location.pathname === "/"
						? navLinks.slice(1).map((entry) => (
								<li
									className={
										entry.link === "My Account" ? "account-tab" : undefined
									}
									onClick={() =>
										entry.link === "My Account" && isLoggedIn
											? navigate("/account")
											: navigation(entry.path)
									}
									key={entry.link}
								>
									{entry.link}
								</li>
						  ))
						: navLinks.map((entry) => (
								<li
									className={
										entry.link === "My Account" ? "account-tab" : undefined
									}
									onClick={() =>
										entry.link === "My Account" && isLoggedIn
											? navigate("/account")
											: navigation(entry.path)
									}
									key={entry.link}
								>
									{entry.link}
								</li>
						  ))}
				</ul>
			</div>
			<div className='navigation__hamburger-container'>
				<FaBars onClick={handlehowMobile} className='navigation__hamburger' />
			</div>
		</div>
	);
}

import React from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import BrowseWizzes from "../Hire-Wiz/BrowseWizzes";
import MakePayment from "../Hire-Wiz/MakePayment";
import CTAS from "./components/CTAS";
import StepLabels from "./StepLabels";
import { DataStore } from '@aws-amplify/datastore';
import { Task } from '../../models';
import { API, graphqlOperation } from 'aws-amplify';
import { createTask } from '../../graphql/mutations';



function SourceTalent({ showMoreWizDetails, handleShowMoreWizDetails }) {
	const taskCategories = [
		'Cooking',
		'House Cleaning',
		'Tutoring',
		'Hair Styling',
		'Laundry',
		'Plumbing'
	];
	const [activeStep, setActiveStep] = React.useState(0);
	const navigate = useNavigate();
	const [taskDetails, setTaskDetails] = React.useState({
		taskCategory: "",
		taskDescription: "",
		taskLocation: "",
		taskStartDate: "",
	});
	const [taskInfoError, setTaskInfoError] = React.useState({
		taskCategory: false,
		taskDescription: false,
		taskLocation: false,
		taskStartDate: false,
	});
	const handleNext = () =>
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	const handleBack = () =>
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	const handleCategoryIndex = (entry) => {
		setTaskDetails({
			...taskDetails,
			taskCategory: entry,
		});
	};
	const handleShowTAskInfoError = (data) => {
		const { taskCategory, taskLocation, taskStartDate } = data;
		setTaskInfoError({
			taskCategory: taskCategory === "" ? true : false,
			taskLocation: taskLocation === "" ? true : false,
			taskStartDate: taskStartDate === "" ? true : false,
		});
	};

	async function handleCheckout() {
		try {
			const response = await API.post('gigwizrestapi', '/checkout', {
				body: {
					"BusinessShortCode": "174379",    
					"Password": "MTc0Mzc5YmZiMjc5ZjlhYTliZGJjZjE1OGU5N2RkNzFhNDY3Y2QyZTBjODkzMDU5YjEwZjc4ZTZiNzJhZGExZWQyYzkxOTIwMTYwMjE2MTY1NjI3",    
					"Timestamp":"20160216165627",    
					"TransactionType": "CustomerPayBillOnline",    
					"Amount": "100",    
					"PartyA":"254708374149",    
					"PartyB":"174379",    
					"PhoneNumber":"254708374149",    
					"CallBackURL": "https://3quwqf07od.execute-api.us-east-2.amazonaws.com/dev/update-transaction",    
					"AccountReference":"Test",    
					"TransactionDesc":"Test"
				}
			});
	
			console.log(response);  // Handle the response, e.g., confirmation to the user
			Swal.fire(
				"Paid!",
				`Your payment has been received and ${taskDetails.assignedWizName} has been notified. The job will move from pending to confirmed status after ${taskDetails.assignedWizName} confirms.`,
				"success"
			);
			navigate("/profile/jobs/orders");

		} catch (error) {
			console.error("Error during checkout:", error);
			Swal.fire(
				"Error!",
				`There was an issue processing your payment. Please try again, or contact us if the issue persists.`,
				"error"
			);
			
		}
	}


	const handlePayment = () => {

		const taskDetails = JSON.parse(localStorage.getItem('taskDetails'));

		Swal.fire({
			title: `Pay ${taskDetails.taskEstimatedCost} for ${taskDetails.assignedWizName}`,
			text: `GigiWiz will hold the funds until the task has been completed.`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, pay now!",
		}).then((result) => {
			if (result.value) {
				handleCheckout();
			}
		});
	};

	async function saveNewTask(task_data) {

		const newTask = await API.graphql(
			graphqlOperation(createTask, { input: task_data})
		);
	}
	const storeTaskDetails = (data) => {
		// store details locally
		localStorage.setItem("taskDetails", JSON.stringify(data));

		console.log(data);
		// push details to data store
		saveNewTask(data);
	};

	return (
		<section className='source-talent'>
			<div className='jobs'>
				<div className='jobs__header'>
					<h2>Hire a Wiz</h2>
				</div>
			</div>
			<div className='source-talent__content'>
				{activeStep === 0 ? (
					<div className='collect-gig-details'>
						<form>
							<div className='form-group'>
								<label className='label' htmlFor='task-category-selection'>
									Select Task
								</label>
								<div className='task-category'>
									<select
										required
										onChange={(e) => {
											handleCategoryIndex(e.target.value);
											handleShowTAskInfoError(taskDetails);
										}}
									>
										<option value=''>Select Task</option>
										{taskCategories.map((category, index) => (
											<option key={index} value={category}>
												{category}
											</option>
										))}
									</select>
								</div>

								{taskDetails.taskCategory !== "" && (
									<div className='category-list'>
										<p>Selected Task: {taskDetails.taskCategory}</p>
									</div>
								)}
								{taskInfoError.taskCategory && (
									<span
										style={{
											color: "red",
											fontSize: "1.3rem",
											fontWeight: "bold",
											width: "100%",
										}}
									>
										Task is required
									</span>
								)}
							</div>
							<div className='form-group'>
								<label className='label' htmlFor='task-description'>
									Please Describe the Problem you Want Solved
								</label>
								<textarea
									id='task-description'
									name='task-description'
									rows='5'
									value={taskDetails.taskDescription}
									onChange={(e) =>
										setTaskDetails({
											...taskDetails,
											taskDescription: e.target.value,
										})
									}
									placeholder='Please Describe the Problem you Want Solved'
								/>
							</div>
							<div className='form-group'>
								<label className='label' htmlFor='task-comencement-date'>
									When do you want to start the task?
								</label>
								<input
									id='task-comencement-date'
									name='task-comencement-date'
									type='date'
									required
									value={taskDetails.taskStartDate}
									onChange={(e) => {
										setTaskDetails({
											...taskDetails,
											taskStartDate: e.target.value,
										});
										handleShowTAskInfoError(taskDetails);
									}}
									placeholder='When do you want to start the task?'
								/>
								{taskInfoError.taskStartDate && (
									<span
										style={{
											color: "red",
											fontSize: "1.3rem",
											fontWeight: "bold",
										}}
									>
										Task Date is required
									</span>
								)}
							</div>
							<div className='form-group'>
								<label className='label' htmlFor='task-location'>
									Where do you want to start the task?
								</label>
								<input
									id='task-location'
									name='task-location'
									type='text'
									required
									value={taskDetails.taskLocation}
									onChange={(e) => {
										setTaskDetails({
											...taskDetails,
											taskLocation: e.target.value,
										});
										handleShowTAskInfoError(taskDetails);
									}}
									placeholder='Enter Task Location'
								/>
								{taskInfoError.taskLocation && (
									<span
										style={{
											color: "red",
											fontSize: "1.3rem",
											fontWeight: "bold",
										}}
									>
										Task Location is required
									</span>
								)}
							</div>
						</form>
					</div>
				) : activeStep === 1 ? (
					<BrowseWizzes
						showMoreWizDetails={showMoreWizDetails}
						handleShowMoreWizDetails={handleShowMoreWizDetails}
					/>
				) : (
					<MakePayment />
				)}

				<CTAS
					nextStep={handleNext}
					storeTaskData={storeTaskDetails}
					handlePayment={handlePayment}
					taskDetails={taskDetails}
					handleShowTAskInfoError={handleShowTAskInfoError}
					step={activeStep}
					prevStep={handleBack}
				/>
			</div>
		</section>
	);
}

export default SourceTalent;

import React, { useState, useRef } from "react";
import { AiOutlineClose } from "react-icons/ai";
import emplImage from "../../../assets/images/empl.jpg";
import { getUserInfo } from "../../../contexts/auth";
import { listMessageThreads, listMessages } from "../../../graphql/queries";
import { createMessage} from "../../../graphql/mutations";
import { API, graphqlOperation } from 'aws-amplify';


function Chat({ closeChat, selectedJob }) {
	const [messageInput, setMessageInput] = useState("");
	const [chatThread, setChatThread] = useState();
	const [ recieverName, setRecieverName] = useState();
	const [ senderId, setSenderId] = useState();
	const [ recieverId, setRecieverId] = useState();
	const [ messages, setMessages] = useState([]);

	 // Sort messages by createdAt
	 const sortedMessages = [...messages].sort((a, b) => {
		const dateA = new Date(a.createdAt);
		const dateB = new Date(b.createdAt);
		return dateA - dateB;
	  });

	  
	  // Ensure the chat container always scrolls to the bottom
	  const chatRef = useRef(null);

	  React.useEffect(() => {
		  if (chatRef.current) {
			  chatRef.current.scrollTop = chatRef.current.scrollHeight;
		  }
	  }, [sortedMessages]);

	const handleSendMessage = async (messageInput) => {

		try {

			const newMessage = {
					TaskId: selectedJob.id,
					senderId: senderId,
					recieverId: recieverId,
					messageBody: messageInput,
					messageStatus: "sent",
					messagethreadID: chatThread.id
				};
			
			if (messageInput.length > 0){
				await API.graphql(graphqlOperation(createMessage, {
					input: newMessage
				}));
			}
			
			// clear the message input field
			setMessageInput("");

			//refresh chat thread
			await fetchChatContext();
			

		} catch (error){
			console.log('Error updating chat thread. ', error);
		}
		
	}


	async function fetchChatContext(){
		try {
			const userInfo = await getUserInfo();
			const userId = userInfo.id; 	

			if ( userId === selectedJob.assignedWizId ) {
				setSenderId(selectedJob.assignedWizId);
				setRecieverId(selectedJob.patronId);
				setRecieverName(selectedJob.patronName);

			} else if ( userId === selectedJob.patronId) {	
				setSenderId(selectedJob.patronId);
				setRecieverId(selectedJob.assignedWizId);
				setRecieverName(selectedJob.assignedWizName);
			} else {
				console.log('Warning: user is neither sender not reciever.')
			}

			// Fetch relevant message thread based on the job id
			const response = await API.graphql(graphqlOperation(listMessageThreads, {
				filter: {
					TaskId: {eq: selectedJob.id }
				  }
			}));
			setChatThread(response.data.listMessageThreads.items[0]);

			// Fetch messages
			const messagearray = await API.graphql(graphqlOperation(listMessages, {
				filter: {
					TaskId: {eq: selectedJob.id }
				  }
			}));
			setMessages(messagearray.data.listMessages.items);
			

		  } catch (error) {  
			console.error('Error Chat context:', error);
		  }
	};

	React.useEffect(() => {
		fetchChatContext();
	  }, []);


	return (
		<div className='chat-container' style={{width:'60vw', marginRight:'-30px'}}>
			<div className='chat-container__header'>
				<div className='chat-container__header--left-side'>
					<div className='chat-container__header--left-side__avatar'>
						<img src={emplImage} alt='avatar' />
					</div>
					<div className='chat-container__header--left-side__name'>
						<h2>{recieverName}</h2>
					</div>
				</div>
				<div className='chat-container__header--right-side'>
					<div
						onClick={closeChat}
						className='chat-container__header--right-side__close'
					>
						<p>
							<AiOutlineClose />
						</p>
					</div>
				</div>
			</div>
			<div className='chat-container__content' >
				<div ref={chatRef} className='chat-container__content--messages' 
					 style={{maxHeight: '400px', overflowY: 'auto'}}>
					{sortedMessages.map((message, index) => (
						<div 
							key={index}
							className={`chat-container__content--messages__message ${
								message.senderId === senderId ? 'outgoing-text' : ''
							}`}
							>
							<p>{message.messageBody}</p>
							</div>
						))}
				</div>
			</div>
			<div className='chat-container__footer'>
				<div className='chat-container__footer--content'>
					<div className='chat-container__footer--content__input'>
						<input
							type='text'
							value={messageInput}
							onChange={(e) => setMessageInput(e.target.value)}
							placeholder='Type a message'
						/>
					</div>
					<div className='chat-container__footer--content__send'>
						<button onClick={() => handleSendMessage(messageInput)}
						        style={{
									background:"#f8d12c",
									padding: '10px',
									borderRadius: '10px'
									}}>Send</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Chat;

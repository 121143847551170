import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components/macro";
import { VscArrowLeft, VscArrowRight } from "react-icons/vsc";
import { team_data } from "./Team";

const TeamCorouselSection = styled.section`
	height: 400px;
	width: 300px;
	position: relative;
	overflow: hidden;
	margin-top: 1rem;
	@media screen and (max-width: 768px) {
		margin-top: 0.5rem;
	}
`;

const CorouselWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
`;
const CorouselEntry = styled.div`
	z-index: 1;
	width: 100%;
	height: 100%;
`;
const CorouselImage = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
`;
const CorouselEntryInfo = styled.div`
	position: relative;
	z-index: 10;
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: auto;
	color: #fff;
	padding: 0.5rem 1rem;
	background: #f8d12c;
	opacity: 0.97;
	height: calc(100% / 6);
	h3 {
		color: #000d1a;
		font-size: 1.8rem;
		font-weight: 800;
	}
	p {
		color: #000d1a;
		font-size: 1.5rem;
		font-weight: 700;
	}
	span {
		font-size: 1.5rem;
		font-weight: 600;
		color: #000d1a;
		margin-left: 2rem;
		font-style: italic;
	}
`;
const CorouselItem = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const SliderButtons = styled.div`
	position: absolute;
	width: 100%;
	bottom: 45%;
	padding: 0 5px 0 5px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 6;
`;
const arrowButtons = css`
	width: 40px;
	height: 40px;
	color: #fff;
	cursor: pointer;
	background: #000d1a;
	border-radius: 50px;
	padding: 5px;
	margin-right: 1rem;
	user-select: none;
	transition: 0.3s;
	&:hover {
		transform: scale(1.05);
	}
`;
const PrevArrow = styled(VscArrowLeft)`
	${arrowButtons}
`;
const NextArrow = styled(VscArrowRight)`
	${arrowButtons}
`;
const TeamCorousel = () => {
	const [current, setCurrent] = useState(0);
	const timeout = useRef(null);
	const length = team_data.length;
	useEffect(() => {
		const nextSlider = () => {
			setCurrent((current) => (current === length - 1 ? 0 : current + 1));
		};
		timeout.current = setTimeout(nextSlider, 10000);
		return function () {
			if (timeout.current) {
				clearTimeout(timeout.current);
			}
		};
	}, [current, length]);

	const nextSlide = () => {
		if (timeout.current) {
			clearTimeout(timeout.current);
		}
		setCurrent(current === length - 1 ? 0 : current + 1);
	};
	const prevSlide = () => {
		if (timeout.current) {
			clearTimeout(timeout.current);
		}
		setCurrent(current === 0 ? length - 1 : current - 1);
	};

	return (
		<TeamCorouselSection>
			<CorouselWrapper>
				{team_data.map((member, index) => {
					return (
						<CorouselEntry key={index}>
							{index === current && (
								<CorouselItem>
									<CorouselImage src={member.image} alt={member.name} />
									<CorouselEntryInfo>
										<div>
											<h3>
												{member.name} <span>Co-Founder</span>
											</h3>
											<p>{member.title}</p>
										</div>
									</CorouselEntryInfo>
								</CorouselItem>
							)}
						</CorouselEntry>
					);
				})}
				<SliderButtons>
					<PrevArrow onClick={prevSlide} />
					<NextArrow onClick={nextSlide} />
				</SliderButtons>
			</CorouselWrapper>
		</TeamCorouselSection>
	);
};
export default TeamCorousel;

import React from "react";
import { Banner } from "../Common";
import bannerImage from "../../assets/images/tph-banner-about2.jpg";
import OurStory from "./OurStory";
import Team from "./Team";
import CTA from "./CTA";

const bannerSubtitle =
	"Get started";

function About() {
	return (
		<div>
			<Banner
				title='What we are about'
				bannerStart={2}
				subtitle={bannerSubtitle}
				image={bannerImage}
			/>
			<OurStory />
			<Team />
			
		</div>
	);
}

export default About;

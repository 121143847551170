import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate, useLocation } from 'react-router-dom';

const initialFormState = { code: "" }
const ConfirmSignup = () => {
    const [formData, setFormData] = useState(initialFormState);
    const [wrongAttempt, setWrongAttempt] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const code = formData.code

    
	const buttonStyle = {
		fontSize: '18px',
		padding: '10px 20px',
		border: '#f8d12c',
		borderRadius: '5px',
		backgroundColor: '#2d324a',
		color: '#f8d12c',
        marginTop: '10px'
	};

    const inputStyle = {
		fontSize: '18px',
		padding: '10px 20px',
		border: '#f8d12c',
		borderRadius: '5px',
		color: '#2d324a'
	};

    const errorStyle = {
        fontSize: '15px',
		padding: '10px 20px',
		color: 'red'
    }
    async function confirmSignUp() {
        try {
            await Auth.confirmSignUp(location.state.username, code);
            localStorage.clear();
            navigate('/profile/jobs', { replace: true })
        } catch (error) {
            setWrongAttempt(true);
            console.log('error confirming sign up', error);
        }
    }
    return (
        <section className='account-section'>
            <div className='auth-page'>
            <div className="grid max-w-screen-xl h-screen text-black m-auto place-content-center">
                <div >
                    <p style={{fontSize:20}}> Enter the confirmation code we sent to {location.state.username} </p>
                    <input
                        onChange={e => setFormData({ ...formData, 'code': e.target.value })}
                        placeholder="confirmation code"
                        value={formData.code}
                        type="text"
                        style={inputStyle}
                    />
                </div>
                <button style={buttonStyle} onClick={confirmSignUp}> Confirm</button>
                {wrongAttempt? <div><p style={errorStyle}>The code you have provided is not correct. Please try again.</p></div> : <div></div>}
            </div>
            </div>
        </section>
    )
}

export default ConfirmSignup
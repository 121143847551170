import React from "react";
import {
	FiChevronDown as ArrowDown,
	FiChevronUp as ArrowUp,
} from "react-icons/fi";
import { BsStarHalf as ReviewIcon } from "react-icons/bs";
import { getUserInfo } from "../../../../../contexts/auth";
import { API, graphqlOperation } from 'aws-amplify';
import { listTasks } from "../../../../../graphql/queries";
import { formatDate } from "../../../../utilities/Util-Functions";

function CompletedGigs() {
	const [showGigInfo, setShowGigInfo] = React.useState(false);
	const [completedGigs, setCompletedGigs] = React.useState();
	const handleShowGigInfo = (index) => {
		if (showGigInfo === index) {
			return setShowGigInfo(null);
		}
		setShowGigInfo(index);
	};

	async function fetchTasks(){
		try {
			const userInfo = await getUserInfo();
			const wizId = userInfo.id; 

			const response = await API.graphql(graphqlOperation(listTasks, {
				filter: {
					assignedWizId: { eq: wizId },
					taskStatus: {eq: 'completed'}
				  }
			}));

			setCompletedGigs(response.data.listTasks.items);
			

		  } catch (error) {
			console.error('Error fetching Tasks:', error);
		  }
	};

	React.useEffect(() => {
		fetchTasks();
	  }, []);


	return (
		<div className='entries-container'>
			{completedGigs?.map((entry, index) => {
				const {
					taskCategory,
					taskDescription,
					taskLocation,
					taskStartDate,
					patronName,
					id,
					updatedAt
				} = entry;
				return (
					<div
						key={id}
						onClick={() => handleShowGigInfo(index)}
						className='entries-container__element'
					>
						<div
							className={
								showGigInfo === index
									? "entries-container__element--data hide-item-contents"
									: "entries-container__element--data"
							}
						>
							<p>{taskCategory}</p>
							<p>{patronName}</p>
							<p>{taskLocation}</p>
							<p>{formatDate(taskStartDate)}</p>
							{showGigInfo === index ? <ArrowUp /> : <ArrowDown />}
						</div>
						{showGigInfo === index ? (
							<div className='entries-container__element--expanded'>
								<div className='entries-container__element--expanded__entry'>
									<p>Job ID:</p>
									<p>{id}</p>
								</div>
								<div className='entries-container__element--expanded__entry'>
									<p>Job Category:</p>
									<p>{taskCategory}</p>
								</div>
								<div className='entries-container__element--expanded__entry'>
									<p>Job Description:</p>
									<p>{taskDescription}</p>
								</div>
								<div className='entries-container__element--expanded__entry'>
									<p>Job Location:</p>
									<p>{taskLocation}</p>
								</div>
								<div className='entries-container__element--expanded__entry'>
									<p>Job Start Date:</p>
									<p>
										{formatDate(taskStartDate)}
									</p>
								</div>
								<div className='entries-container__element--expanded__entry'>
									<p>Job Completion Date:</p>
									<p>
										{formatDate(updatedAt)}
									</p>
								</div>
								<div className='entries-container__element--expanded__chat-container'>
									<div className='entries-container__element--expanded__chat-container--chat-button'>
										<ReviewIcon />
										<span>Rate Employer</span>
									</div>
								</div>
								{/* <div className='entries-container__element--expanded__buttons-container'>
									<button className='entries-container__element--expanded__buttons-container--button cancel-job'>
										Cancel Job
									</button>
								</div> */}
							</div>
						) : null}
					</div>
				);
			})}
		</div>
	);
}

export default CompletedGigs;

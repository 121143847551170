import React from 'react'
import BecomeWizComponent from '../../components/Become-Wiz/BecomeWiz'

function BecomeWiz() {
  return (
    <div>
      <BecomeWizComponent/>
    </div>
  )
}

export default BecomeWiz
import React from "react";
import { BsFillTelephoneFill as Phone } from "react-icons/bs";
import { BsEnvelopeFill as EmailIcon }  from "react-icons/bs";
import { FaUserAlt } from "react-icons/fa";
import { FaUnlockAlt } from "react-icons/fa";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { Auth } from 'aws-amplify'
import { API } from '@aws-amplify/api'
import config from '../../aws-exports'
import { DataStore } from '@aws-amplify/datastore';
import { GigwizUser, PatronProfile,  WorkerProfile} from '../../models';

API.configure(config)

function SignUp({ logIngPageRedirect, handleShowPassword, showPassword }) {
	
	const navigate = useNavigate();
	const [password, setPassword] = React.useState('');
	const [contact_phone_number, setContactPhoneNumber] = React.useState('');
	const [email, setEmail] = React.useState('');
	const [first_name, setFirstName] = React.useState('');
	const [last_name, setLastName] = React.useState('');
	const [terms, setTerms] = React.useState('');
	const [password2, setConfirmPassword] = React.useState("");
	const [noPasswordError, setNoPasswordError] = React.useState(true);
	const [passwordValid, setPasswordValid] = React.useState(true);
	const [emptyFields, setEmptyFields] = React.useState(true);
	const [phonenumberFormat, setPhonenumberFormat] = React.useState(true);
	const [emailFormat, setEmailFormat] = React.useState(true);

	function isValidPassword(password) {
		const hasLowercase = /[a-z]/.test(password);
		const hasUppercase = /[A-Z]/.test(password);
		const hasNumerals = /[0-9]/.test(password);
		const hasSymbols = /[!@#$%^&*()]/.test(password);
	  
		return (
		  password.length >= 8 &&
		  hasLowercase &&
		  hasUppercase &&
		  hasNumerals &&
		  hasSymbols
		);
	  }

	function checkEmptyFields() {
		return (
			first_name == '' ||
			last_name == '' ||
			terms == '' ||
			email == '' ||
			contact_phone_number == ''
		);
	}

	function cleanPhoneNumber(phone) {
		return phone.replace(/[^\d+]/g, '');
	}

	function checkPhoneNumberFormat(){
		return (
			contact_phone_number == '' ||
		    contact_phone_number.startsWith('+')
		)
	}

	function checkEmailFormat(){
		return (
			email == '' ||
			/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/.test(email)
		)
	}

	React.useEffect(() => {
		setEmptyFields(checkEmptyFields());
		setPhonenumberFormat(checkPhoneNumberFormat());
		setEmailFormat(checkEmailFormat());
	});


	async function createUser() {

		const userObj = new GigwizUser({
			"phone_number": contact_phone_number,
			"email": email,
			"date_of_birth": "1970-01-01Z",
			"langauges": [],
			"verified": false,
			"password": "",
			"first_name": first_name,
			"last_name": last_name
		});
		
		const response = await DataStore.save(userObj);
	 	console.log(response);

		await DataStore.save(
			new WorkerProfile({
				"first_name": first_name,
				"gigwiz_user_id": userObj.id,
				"status": true,
				"services": [],
				"work_locations": [],
				"experience": "less than 1 year",
				"main_service": "",
				"rating": 3.0
			})
		);

		await DataStore.save(
			new PatronProfile({
				"first_name": first_name,
				"gigwiz_user_id": userObj.id,
				"status": true,
				"rating": 3.0
			})
		);

	 };

	const signUp = async () => {
		try {
			const { user } = await Auth.signUp({
				username: email,
				password: password,
				attributes: {
					name: first_name + ' ' + last_name,
					phone_number: contact_phone_number,
					email: email 
				}
			});
			createUser();
			navigate('/confirm',{
				state: {username: email }
			});


		} catch (error) {
			console.log('error signing up:', error);
			navigate('/account')
		}

	}
	return (
		<div className='auth-page-container'>
			<h2>Create Your Account</h2>
			<form
			>
				<div className='user-name'>
					<FaUserAlt />
					<input
						type='text'
						className='form-control'
						required
						id='first_name'
						onChange={(e) =>
							setFirstName(e.target.value)
						}
						value={first_name}
						placeholder='Enter First Name'
					/>
					<input
						type='text'
						className='form-control'
						id='last_name'
						required
						onChange={(e) =>
							setLastName(e.target.value)
						}
						value={last_name}
						placeholder='Enter Last Name'
					/>
				</div>
				<div className='form-group'>
					<EmailIcon />
					<input
						type='text'
						className='form-control'
						required
						id='email'
						onChange={(e) =>
							setEmail(e.target.value)
						}
						value={email}
						placeholder='Enter Email Address'
					/>
				</div>
				<div className='form-group'>
					<Phone />
					<input
						type='text'
						className='form-control'
						required
						id='contact_phone_number'
    					title="Please include your country code. E.g., +254 123-456-7890"
						onChange={(e) =>
							setContactPhoneNumber(cleanPhoneNumber(e.target.value))
						}
						value={contact_phone_number}
						placeholder='E.g., +254 123-456-7890'
					/>
				</div>
				<div className='form-group'>
					<FaUnlockAlt />
					<input
						type={showPassword ? "text" : "password"}
						className='form-control'
						id='password'
						required
						onChange={(e) =>
							setPassword(e.target.value )
						}
						value={password}
						placeholder='Enter password'
					/>
					<div onClick={handleShowPassword} className='show-password'>
						{showPassword ? (
							<AiFillEyeInvisible title='Hide Password' />
						) : (
							<AiFillEye title='Show Password' />
						)}
					</div>
				</div>
				<div className='form-group'>
					<FaUnlockAlt />
					<input
						type={showPassword ? "text" : "password"}
						className='form-control'
						id='password-confirm'
						required
						onChange={(e) => 
							setConfirmPassword(e.target.value)
						}
						onBlur={() => {
							if (password !== password2) {
								setNoPasswordError(false);
							} else {
								setNoPasswordError(true);
								if (isValidPassword(password2)){
									setPasswordValid(true);
								} else {
									setPasswordValid(false);
								}
							}
						}}
						value={password2}
						placeholder='Confirm Your password'
					/>
					<div onClick={handleShowPassword} className='show-password'>
						{showPassword ? (
							<AiFillEyeInvisible title='Hide Password' />
						) : (
							<AiFillEye title='Show Password' />
						)}
					</div>
				</div>
				<span
					style={{ color: "red", fontSize: "1.2rem" }}
					className='error-message'
				>
					{!noPasswordError
						? " The passwords entered do not match. Please check and try again."
						: ""}
					{!passwordValid
						? " Your password must have at least 8 characters, lnclude lowercase characters, include uppercase characters, include numerals and include symbols. "
						: ""}
					{emptyFields
						? " All fields are mandatory"
						: ""}
					{!phonenumberFormat
						? " Phone number must include a country code"
						: ""}
					{!emailFormat
						? " Please enter a valid email"
						: ""}
				</span>
				<div className=' terms'>
					<input
						type='checkbox'
						name='terms'
						required
						onChange={(e) =>
							setTerms(e.target.value)
						}
						checked={terms}
						id='terms'
					/>
					<p>
						I agree to the GigWiz's <u>Terms of Service</u> and{" "}
						<u>Privacy Policy</u>
					</p>
				</div>
				<div className='buttons-container' >
					
					{passwordValid && noPasswordError && phonenumberFormat && emailFormat &&(
						<button
							type='submit'
							className='btn btn-primary buttons-container__submit'
							style={{
								minWidth: "150px",
								margin: "auto"
							}}
							onClick={(e) => {
								signUp(e);
							}}
						>
							Submit
						</button>
					)}
				</div>
			</form>
			<p className='sign-in'>
				Already Have an Account?{" "}
				<u onClick={logIngPageRedirect}>Sign In Instead</u>
			</p>
		</div>
	);
}

export default SignUp;
import React from "react";
import { useNavigate } from "react-router-dom";
import gigWizLogo from "../../../assets/images/gigwiz-logo.png";
import { GiHamburgerMenu as HamburgerIcon } from "react-icons/gi";
import { GoSignOut as SignOutIcon } from "react-icons/go";
import { useGigWizContext } from "../../../contexts/Gigwiz";

function TopBarMobile() {
	const navigate = useNavigate();
	const { handleShowUserProfileMenu } = useGigWizContext();
	return (
		<div className='user-profile-section__container--top-bar-mobile'>
			<div
				onClick={handleShowUserProfileMenu}
				className='user-profile-section__container--top-bar-mobile__menu'
			>
				<HamburgerIcon />
			</div>
			<div
				onClick={() => navigate("/")}
				className='user-profile-section__container--top-bar-mobile__logo'
			>
				<img src={gigWizLogo} alt='gigwiz-logo' />
			</div>
			<div className='user-profile-section__container--top-bar-mobile__profile-settings'>
				<p onClick={()=>navigate('/account')}>
					<SignOutIcon />
				</p>
			</div>
		</div>
	);
}

export default TopBarMobile;

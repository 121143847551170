import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { updateWorkerProfile } from '../../../../graphql/mutations';
import { DataStore } from '@aws-amplify/datastore';
import { GigwizUser, WorkerProfile } from '../../../../models';
import { getCurrentUser } from "../../../../contexts/auth";
import { useNavigate } from "react-router-dom";

const WorkerProfileForm = () => {
  const navigate = useNavigate();
  const [services, setServices] = useState('');
  const [gigwizUser, setCurrentUser] = useState(null);
  const [profileid, setProfileId] = useState('');
  const [serviceOptions, setServiceOptions] = useState([]);

  const skillsOptions = [
    'Cooking',
    'House Cleaning',
    'Tutoring',
    'Hair Styling',
    'Laundry',
    'Plumbing',
  ];

  useEffect(() => {
    getGigWizUser();
  }, []);

  const getGigWizUser = async () => {
    try {
      // Get the current user object
      const curr = getCurrentUser();
      const resultArray = await DataStore.query(
        GigwizUser, 
        c => c.phone_number.eq(curr.phone_number)
      );
      const userObj = resultArray[0];
      setCurrentUser(userObj);

      // Get user's worker profile
			const resultArray2 = await DataStore.query(
				WorkerProfile,
				c => c.gigwiz_user_id.eq(userObj.id)
			);
      const profile = resultArray2[0];
      setProfileId(profile.id);
      
      // Exclude services that are aleady offered by this user
      const options = skillsOptions.filter(element => !profile.services.includes(element));
      setServiceOptions(options);

    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!gigwizUser || services=='') return;

    try {
      await API.graphql(
        graphqlOperation(updateWorkerProfile, {
          input: {
            id: profileid,
            gigwiz_user_id: gigwizUser.id,
            services: services
          },
        }),
      );
      alert('success: ' + services + ' was added to your service offerings.');
      console.log('Profile successfully updated');
      navigate('/profile/jobs');

    } catch (error) {
      alert('Error: Adding the new survice was not failed. Please Try again or contact the GigWiz team.')
      console.error('Error updating profile:', error);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="skills">Service:</label>
          <select
            id="skills"
            value={services}
            onChange={(e) => setServices(e.target.value)}
          >
            <option value="">Select a service</option>
            {serviceOptions.map((skill, index) => (
              <option key={index} value={skill}>
                {skill}
              </option>
            ))}
          </select>
        </div>
        <button className='change-password__button' type="submit">Add Service</button>
      </form>
    </div>
  );
};

export default WorkerProfileForm;

import React from "react";
import {Hypnosis} from "react-cssfx-loading";
function Loading() {
	return (
		<div className='loading-div'>
			<div>
				<Hypnosis
					color={"#2d324a"}
					width='100px'
					height='100px'
					duration='3s'
				/>
			</div>
			<h1 style={{ margin: "1vh 0.5vh", color: "#2d324a" }}>Loading...</h1>
		</div>
	);
}

export default Loading;

import React from "react";
import { useEffect } from "react";

function MakePayment() {
	
	const formatCurrency = Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "KES",
	});
	const [retrievedData, setRetrievedData] = React.useState(null);
	
	
	useEffect(() => {
		function fetchLocalData() {
			const data = localStorage.getItem("taskDetails");
			setRetrievedData(JSON.parse(data));	
		}
		fetchLocalData();
	},[]);

	

	console.log(retrievedData);
	return (
		<section className='gig-assgnment-preview-makepayment'>
			<h1>Gig Assignment Preview</h1>
			<div className='gig-assgnment-preview-makepayment__content'>
				<div className='gig-assgnment-preview-makepayment__content--task'>
					<p>Task</p>
					<p>{retrievedData?.taskCategory}</p>
				</div>
				<div className='gig-assgnment-preview-makepayment__content--task-description'>
					<p>Task Description</p>
					<p>{retrievedData?.taskDescription?retrievedData?.taskDescription : "No details Provided"}</p>
				</div>
				<div className='gig-assgnment-preview-makepayment__content--task-location'>
					<p>Task Location</p>
					<p>{retrievedData?.taskLocation}</p>
				</div>
				<div className='gig-assgnment-preview-makepayment__content--task-date'>
					<p>Task Date</p>
					<p>{retrievedData?.taskStartDate}</p>
				</div>
				<div className='gig-assgnment-preview-makepayment__content--selected-wiz'>
					<p>Selected Wiz</p>
					<p>{retrievedData?.assignedWizName}</p>
				</div>
				<div className='gig-assgnment-preview-makepayment__content--total-amount'>
					<p>Estimated Cost</p>
					<p>{formatCurrency.format(retrievedData?.taskEstimatedCost)}</p>
				</div>
			</div>
		
		</section>
	);
}

export default MakePayment;

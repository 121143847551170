import React, { useEffect, useState } from "react";
import { leftSideData } from "../MainProfileComponent";
import emplImage from "../../../assets/images/empl.jpg";
import { GoSignOut as SignOutIcon } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { useGigWizContext } from "../../../contexts/Gigwiz";
import Rating from "@mui/material/Rating";
import { getUserInfo, isLoggedIn } from '../../../contexts/auth'
import {logout} from "../../../contexts/auth";
import { Storage } from 'aws-amplify';
import { listPatronProfiles } from "../../../graphql/queries";
import { API, graphqlOperation } from 'aws-amplify';

Storage.configure({ track: true, level: "private" });

function SideBar() {
	const navigate = useNavigate();
	const { userData, handleOpen } = useGigWizContext();
	const [getNavData, setNavData] = useState(null);
	const [image, setImage] = useState(emplImage);
	const [patronProfile, setPatronProfile] = useState();

	async function fetchProfile(){
		try {
			const userInfo = await getUserInfo();

			const response = await API.graphql(graphqlOperation(listPatronProfiles, {
				filter: {
					gigwiz_user_id: { eq: userInfo.id }
				  }
			}));
			setPatronProfile(response.data.listPatronProfiles.items[0]);

		} catch (error){
			console.log('Error fetching user profile:', error);
		}
	}

	useEffect(() => {
		fetchProfile();
	});
	
	useEffect(() => {
		if (userData) {
			setNavData({
				id: userData?.id,
				wiz_details_updated: userData?.details.wiz_details_updated,
			});
		}
	}, [userData]);

	let fileInput = React.createRef();

	const onOpenFileDialog = () => {
		fileInput.current.click();
	};

	const onProcessFile = e => {
		e.preventDefault();
		let reader = new FileReader();
		let file = e.target.files[0];
		try {
			reader.readAsDataURL(file);
		} catch (err) {
			console.log(err);
		}
		reader.onloadend = () => {
			setImage(reader.result);
		};
		Storage.put("profilePicture.png", file, {
			contentType: "image/png"
		})
			.then(result => console.log(result))
			.catch(err => console.log(err));
	};

	const saveDefaultProfiePicture = () => {

		Storage.put("profilePicture.png", emplImage, {
			contentType: "image/png"
		})
			.then(result => console.log(result))
			.catch(err => console.log(err));
	};

	useEffect(() => {
		getProfilePicture();
	  }, []);
	
	
	const getProfilePicture = () => {
		Storage.get("profilePicture.png")
		  .then(url => {
			var myRequest = new Request(url);
			fetch(myRequest).then(function(response) {
			  if (response.status === 200) {
				setImage(url);
			  } else {
				saveDefaultProfiePicture();
			  }
			});
		  })
		  .catch(err => ( console.log(err)) );
	  };

	
	  
	return (
		isLoggedIn()?
			<div className='user-profile-section__container--main__left-side'>
				<div className='user-profile-section__container--main__left-side--profile-image'>
					<a href="/">
						<input
							type="file"
							key='profile_picture'
							onChange={onProcessFile}
							ref={fileInput}
							hidden={true}
						/>
					</a>
					<img src={image} alt='click here to update profile photo' onClick={onOpenFileDialog} title='click to update image' />
					<p>{patronProfile?.first_name || 'Guest User'}</p>
					<span style={{fontSize:'1rem'}}>
						<Rating
							name='half-rating-read'
							value={patronProfile?.average_rating || 5}
							precision={0.5}
							style={{ color: "#ffb400" }}
							readOnly
							state='undefined'
						/>
					</span>
				</div>
				<div className='user-profile-section__container--main__left-side--navigation'>
					<ul>
						{leftSideData.map((item) => {
							const { id, icon, title, path } = item;
							return id === 5 &&
								getNavData?.wiz_details_updated === false ? null : id === 4 &&
							getNavData?.wiz_details_updated ? null : (
								<li
									key={id}
									onClick={id === 5 ? handleOpen : () => navigate(path)}
								>
									<p>
										{icon}
										<span>{title}</span>
									</p>
								</li>
							);
						})}
					</ul>
					<div className='user-profile-section__container--main__left-side--navigation__sign-out'>
						<p onClick={ () => {
								logout()
								navigate('/account')
						}}>
							<SignOutIcon /> Sign Out
						</p>
					</div>
				</div>
			  </div>
			: <div><h2 style={{color: 'black'}}>User not Logged in</h2></div>
	);
}

export default SideBar;

import { createContext, useContext, useEffect, useState } from "react";
import { paymentsInfo, pastPayments } from "./dummyBill";
import axios from "../axios";

export const GigWizContext = createContext();
export const useGigWizContext = () => useContext(GigWizContext);

export function GigWizProvider({ children }) {
	const title = "GigWiz Context Heading";
	const [showMobile, setShowMobile] = useState(false);
	const [isLoggedIn, setIsLoggedIn] = useState(true);
	const [showHireWiz, setShowHireWiz] = useState(false);
	const [showUserProfileMenu, setShowPaymentWiz] = useState(false);
	const [continueHireWiz, setContinueHireWiz] = useState(false);
	const [showMoreWizDetails, setShowMoreWizDetails] = useState(false);
	const [selectedWizFromList, setSelectedWizFromList] = useState(null);
	const handleSelectedWizFromList = (data, id) => {
		const currentWiz = data.find((wiz) => wiz.id === id);
		setSelectedWizFromList(currentWiz);
	};
	const handleShowMoreWizDetails = () => {
		setShowMoreWizDetails(!showMoreWizDetails);
	};
	const [wizAvailabilityDate, setWizAvailabilityDate] = useState([]);
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [showWizData, setShowWizData] = useState(false);
	const handleShowWizData = () => setShowWizData(true);
	const handleHideWizData = () => setShowWizData(false);
	const [userData, setUserData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const handleIsLoggedIn = () => setIsLoggedIn(true);
	const handleContinueHireWiz = () => setContinueHireWiz(true);

	const [selectedWiz, setSelectedWiz] = useState(null);
	const [newGigCategory, setNewGigCategory] = useState("");
	const [newGigLocation, setNewGigLocation] = useState("");
	const [newGigDescription, setNewGigDescription] = useState("");
	const [newGigDate, setNewGigDate] = useState("");

	const getBillDetails = (billData, id) => {
		return billData?.find((bill) => bill.id === +id);
	};

	const handleSelectedWiz = (wiz) => {
		setSelectedWiz(wiz);
	};

	const handleShowHireWiz = () => {
		setShowHireWiz(true);
	};
	const handleTrackGig = (gig) => {
		const { location, category, description, date } = gig;
		setNewGigLocation(location);
		setNewGigCategory(category);
		setNewGigDescription(description);
		setNewGigDate(date);
	};

	const handlehowMobile = () => setShowMobile(!showMobile);
	const handleShowUserProfileMenu = () =>
		setShowPaymentWiz(!showUserProfileMenu);

	useEffect(() => {
		async function getUserData() {
			const {
				data: { user: details },
			} = await axios.get();

			setUserData(details);
			setIsLoading(false);
			return details;
		}
		getUserData();
	}, []);

	const values = {
		handlehowMobile,
		showMobile,
		title,
		isLoggedIn,
		newGigLocation,
		newGigCategory,
		open,
		handleOpen,
		handleClose,
		wizAvailabilityDate,
		setWizAvailabilityDate,
		newGigDescription,
		showHireWiz,
		handleShowHireWiz,
		newGigDate,
		handleTrackGig,
		handleSelectedWiz,
		selectedWiz,
		paymentsInfo,
		getBillDetails,
		pastPayments,
		handleShowUserProfileMenu,
		showUserProfileMenu,
		isLoading,
		userData,
		handleIsLoggedIn,
		continueHireWiz,
		handleContinueHireWiz,
		showMoreWizDetails,
		handleShowMoreWizDetails,
		handleShowWizData,
		showWizData,
		handleHideWizData,
		selectedWizFromList,
		handleSelectedWizFromList,
	};
	return (
		<GigWizContext.Provider value={values}>{children}</GigWizContext.Provider>
	);
}

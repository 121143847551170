import React from "react";
import { RequestPasswordReset, SignIn, SignUp } from "../AuthenticationPages";
import bgImage from "../../assets/images/istockphoto-855528872-612x612.jpg";

function Account() {
	const [registerPage, setRegisterPage] = React.useState(false);
	const [logInPage, setLogInPage] = React.useState(true);
	const [passwordResetPage, setPsswordResetPage] = React.useState(false);
	const [showPassword, setShowPassword] = React.useState(false);

	const handleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const registerPageRedirect = () => {
		setRegisterPage(true);
		setLogInPage(false);
		setPsswordResetPage(false);
	};
	const logIngPageRedirect = () => {
		setRegisterPage(false);
		setLogInPage(true);
		setPsswordResetPage(false);
	};
	const passwordResetPageRedirect = () => {
		setRegisterPage(false);
		setLogInPage(false);
		setPsswordResetPage(true);
	};
	return (
		<section className='account-section'>
			<div className='auth-page'>
				{registerPage && (
					<SignUp
						logIngPageRedirect={logIngPageRedirect}
						handleShowPassword={handleShowPassword}
						showPassword={showPassword}
					/>
				)}
				{logInPage && (
					<SignIn
						passwordResetPageRedirect={passwordResetPageRedirect}
						registerPageRedirect={registerPageRedirect}
						handleShowPassword={handleShowPassword}
						showPassword={showPassword}
					/>
				)}
				{passwordResetPage && (
					<RequestPasswordReset
						logIngPageRedirect={logIngPageRedirect}
						handleShowPassword={handleShowPassword}
						showPassword={showPassword}
					/>
				)}
			</div>
			<div className='account-section__background-image'>
				<img src={bgImage} alt='auth-page-bg' />
			</div>
			<div className='account-section__overlay'></div>
		</section>
	);
}

export default Account;

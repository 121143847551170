import React from "react";
import bannerBg from "../../assets/images/istockphoto-855528872-612x612.jpg";
import { Banner } from "../Common";

const banner_title = "You have a Job? We get it done.";
const banner_subtitle =
	"Get started";

function HireWizComponent() {

	return (
		
		<div className='hirewiz-component'>
			<Banner
				bannerStart={4}
				searchVisibility={true}
				title={banner_title}
				subtitle={banner_subtitle}
				image={bannerBg}
			/>
		
		</div>
	
		
	);
}

export default HireWizComponent;
